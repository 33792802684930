import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";

import { Dish, ButtonPrimary, MobilePageHeader } from "../../components";
import { Mobile, Tablet, Desktop } from "../../theme";
import { Header, SubHeader, Description } from "../../styles";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 0rem 1rem;
`;

const Spacer = styled.div`
  padding-top: 3rem;
`;

const Call = styled.a`
  font-size: 17px;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.4rem 1rem 0.2rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #e96565;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
  }

  @media (min-width: 901px) {
    font-size: 25px;
  }
`;

const Favorites = () => {
  const { favorites } = useContext(RestaurantContext);

  return (
    <div>
      {favorites && favorites.length > 0 ? (
        <Container>
          <Mobile>
            <MobilePageHeader
              header="Mina Favoriter"
              text="Hej vad kul att du hittat några favoriter i vårt stora utbud av
						catering. Om du är redo nu kan du ringa oss på numret nedan för
						att lägga din beställning. Hoppas det smakar!"
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <SubHeader dark>Ring in din beställning</SubHeader>
              <Call href="tel:0322-784 68">0322-784 68</Call>
            </div>
          </Mobile>
          <Tablet>
            <MobilePageHeader
              header="Mina Favoriter"
              text="Hej vad kul att du hittat några favoriter i vårt stora utbud av
						catering. Om du är redo nu kan du ringa oss på numret nedan för
						att lägga din beställning. Hoppas det smakar!"
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <SubHeader dark>Ring in din beställning</SubHeader>
              <Call href="tel:0322-784 68">0322-784 68</Call>
            </div>
          </Tablet>
          <Desktop>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "5rem"
              }}
            >
              <Header dark>Mina Favoriter</Header>
              <Description dark>
                Hej vad kul att du hittat några favoriter i vårt stora utbud av
                catering. Om du är redo nu kan du ringa oss på numret nedan för
                att lägga din beställning. Hoppas det smakar!
              </Description>
              <SubHeader dark>
                Ring in din beställning på <br />
                0322 - 784 68
              </SubHeader>
              <Spacer />
            </div>
          </Desktop>
        </Container>
      ) : (
        <Container>
          <Mobile>
            <MobilePageHeader
              header="Mina Favoriter"
              text="Hej! Det verkar som att du inte har markerat några favoriter än."
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <ButtonPrimary label="Gå till Catering?" path="/catering" />
              <Spacer />
            </div>
          </Mobile>
          <Tablet>
            <MobilePageHeader
              header="Mina Favoriter"
              text="Hej! Det verkar som att du inte har markerat några favoriter än."
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <ButtonPrimary label="Gå till Catering?" path="/catering" />
              <Spacer />
            </div>
          </Tablet>
          <Desktop>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "5rem"
              }}
            >
              <Header dark>Mina Favoriter</Header>
              <Description dark center>
                Hej! Det verkar som att du inte har markerat några favoriter än.
              </Description>
              <ButtonPrimary label="Gå till Catering?" path="/catering" />
              <Spacer />
            </div>
          </Desktop>
        </Container>
      )}

      <Container>
        {favorites && favorites.length > 0
          ? favorites.map(favorite => {
              return <Dish key={favorite.id} dish={favorite} />;
            })
          : null}
      </Container>
    </div>
  );
};

export default Favorites;
