import React, { useContext, Fragment } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

import { RestaurantContext } from "../../context";
import { fetchServices } from "../../utils";

import { Landing, Service, Contact, Takeaway } from "../../components";
import { Desktop } from "../../theme";

const Container = styled.div``;

const Home = () => {
  const { restaurant, restaurantData } = useContext(RestaurantContext);
  const services = fetchServices(restaurant);

  if (restaurant) {
    return (
      <Container>
        <main>
          {restaurant && restaurant === "edi-ali-hem" && (
            <Fragment>
              <Takeaway />
              <Desktop>
                <Landing />
              </Desktop>
            </Fragment>
          )}
          {services &&
            services.map((service) => (
              <section key={service.id}>
                <Service
                  type={service.type}
                  data={service.data}
                  restaurantData={restaurantData}
                />
              </section>
            ))}
          <section>
            <Contact restaurant={restaurant} />
          </section>
        </main>
      </Container>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default Home;
