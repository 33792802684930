import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import { RestaurantContext } from "../../context";
import { fetchContactData } from "../../utils";

import { Link } from "../../components";
import { BackgroundImage, Overlay } from "../../styles";
import { Mobile, Tablet, Desktop } from "../../theme";

const Container = styled.div`
  flex: 1;
`;

const Spacer = styled.div`
  height: 2rem;

  @media (min-width: 601px) and (max-width: 900px) {
    height: 3rem;
  }

  @media (min-width: 901px) {
    height: 1rem;
  }
`;

const Header = styled.div`
  font-family: "Timber";
  font-size: 30px;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-transform: uppercase;
  text-align: center;
  width: 80%;
  line-height: 1;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 46px;
  }

  @media (min-width: 901px) {
    font-size: 90px;
  }
`;

const SubHeader = styled.div`
  font-family: "Timber";
  font-size: 17px;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-transform: uppercase;
  text-align: center;
  width: 80%;
  padding-top: 1.5rem;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
  }

  @media (min-width: 901px) {
    font-size: 40px;
    width: 65%;
  }
`;

const Text = styled.div`
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  width: 80%;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
  }

  @media (min-width: 901px) {
    font-size: 28px;
    width: 65%;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled(RouterLink)`
  margin-right: 2rem;
  margin-left: 2rem;
  color: rgba(255, 255, 255, 0.8);
`;

const Contact = () => {
  const { restaurant } = useContext(RestaurantContext);
  const ContactData = fetchContactData(restaurant);

  return (
    <Container>
      <Mobile>
        <BackgroundImage src="sushi" height="500">
          <Overlay>
            <Header>Öppettider</Header>
            {ContactData &&
              ContactData.openHours &&
              ContactData.openHours.map(({ id, day, time }) => {
                return (
                  <Text key={id}>
                    {day} {time}
                  </Text>
                );
              })}
            <Spacer />
            <Header>Kontakta Oss</Header>
            <LinkContainer>
              <Link path={`tel:${ContactData.phone}`} label="Ring" />
              <Link path={`mailto:${ContactData.email}`} label="E-post" />
            </LinkContainer>
            <Spacer />
            <Header>Hitta hit</Header>
            <Link path={ContactData.googleMapsUrl} noborder>
              <img src="/images/map32.png" alt="Öppna karta" height="32" />
            </Link>
            <Text>{ContactData.adress}</Text>
            <Text>
              {ContactData.zip} {ContactData.city}
            </Text>
            <Spacer />
            <Links>
              <LinkStyled to="/integritetspolicy">Integritetspolicy</LinkStyled>
              <LinkStyled to="/leveransvilkor">Leveransvilkor</LinkStyled>
            </Links>
          </Overlay>
        </BackgroundImage>
      </Mobile>
      <Tablet>
        <BackgroundImage src="sushi" height="700">
          <Overlay>
            <Header>Öppettider</Header>
            {ContactData &&
              ContactData.openHours &&
              ContactData.openHours.map(({ id, day, time }) => {
                return (
                  <Text key={id}>
                    {day} {time}
                  </Text>
                );
              })}
            <Spacer />
            <Header>Kontakta Oss</Header>
            <LinkContainer>
              <Link path={`tel:${ContactData.phone}`} label="Ring" />
              <Link path={`mailto:${ContactData.email}`} label="E-post" />
            </LinkContainer>
            <Spacer />
            <Header>Hitta hit</Header>
            <Link path={ContactData.googleMapsUrl} noborder>
              <img src="/images/map48.png" alt="Öppna karta" height="48" />
            </Link>
            <Text>
              {ContactData.adress}, {ContactData.zip} {ContactData.city}
            </Text>
            <Spacer />
            <Links>
              <LinkStyled to="/integritetspolicy">Integritetspolicy</LinkStyled>
              <LinkStyled to="/leveransvilkor">Leveransvilkor</LinkStyled>
            </Links>
          </Overlay>
        </BackgroundImage>
      </Tablet>
      <Desktop>
        <BackgroundImage src="sushi">
          <Overlay>
            <Header>Kontakta Oss</Header>
            <Spacer />
            <SubHeader>Telefon</SubHeader>
            <Text>{ContactData.phone}</Text>
            <LinkContainer>
              <Link path={`mailto:${ContactData.email}`} label="Maila Oss" />
            </LinkContainer>
            <Spacer />
            <SubHeader>Öppettider</SubHeader>
            {ContactData &&
              ContactData.openHours &&
              ContactData.openHours.map(({ id, day, time }) => {
                return (
                  <Text key={id}>
                    {day} {time}
                  </Text>
                );
              })}
            <Spacer />
            <SubHeader>Adress</SubHeader>
            <Text>{ContactData.adress}</Text>
            <Text>
              {ContactData.zip} {ContactData.city}
            </Text>
            <Spacer />
            <SubHeader>Hitta hit</SubHeader>
            <Link path={ContactData.googleMapsUrl} noborder nohover>
              <img src="/images/map64.png" alt="Öppna karta" height="64" />
            </Link>
          </Overlay>
        </BackgroundImage>
      </Desktop>
    </Container>
  );
};

export default Contact;
