import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";

import { BackgroundImage, Overlay, Header, Description } from "../../styles";
import { ButtonLink } from "../../components"

const Container = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  margin-bottom: 3px;
`;

const RestarauntData = [
  {
    restaurant: "edi-ali-hem",
    header: "EDITHS RESTAURANG",
    image: "lax",
    giftcardtext: "Har du något eller någon att fira? Här kan du köpa ett digitalt presentkort som går att använda på alla Mat & Mingels restauranger.",
    giftcardlink: "https://giftcards.microdeb.me/company/fa5c8290-429b-492a-99f1-014b30171c67",
    giftcardlinktext: "Köp presentkort",
    description:
      "I vår restaurang bjuds du på allt från frukost till dagens lunch, sallad, härliga mackor och mycket, mycket annat gott. Och är du sugen på ett glas rött, vitt eller en kall öl till din mat så kan vi givetvis servera det. Välkommen in!",
    data: [
      /*{
        id: 1,
        header: "PIZZA",
        text:
          "Våra pizzor kan du avnjuta på såväl lunchen mitt i veckan eller på helgen. Vill du hellre avnjuta en av stans bästa pizzor hemma i soffan, då kan du givetvis ta med dig den hem.",
        image: "pizza"
      },
      {
        id: 2,
        header: "SUSHI",
        text:
          "Vår senaste nyhet är helt rå. Nu erbjuder vi även egenlagad Sushi i alla dess former. Givetvis använder vi de fräschaste råvarorna vi kan hitta. Välkommen in på lite Japanskt hemlagat.",
        image: "sushi"
      },*/
      {
        id: 3,
        header: "SALLADER",
        text:
          "Ibland är en snabb sallad det enda man vill ha. Då passar våra färska, nygjorda sallader perfekt. Samtliga komponerade med omsorg och noga utvalda råvaror.",
        image: "sallad"
      },
      {
        id: 4,
        header: "FÄRDIGA MACKOR",
        text:
          "Bara lite sugen? Eller på språng och behöver något lite snabbt. Då har vi underbart goda mackor gjorda på hembakat bröd från eget bageri. Mmmm…",
        image: "fralla"
      }
    ]
  },
  {
    restaurant: "edi-ali-sod",
    header: "EDITHS RESTAURANG",
    image: "lax",
    giftcardtext: "",
    giftcardlink: "",
    giftcardlinktext: "",
    description:
      "I vår restaurang bjuds du på allt från dagens lunch, sallad, härliga mackor och mycket annat gott. Välkommen in på lite hemlagat!",
    data: [
      {
        id: 3,
        header: "SALLADER",
        text:
          "Ibland är en snabb sallad det enda man vill ha. Då passar våra färska, nygjorda sallader perfekt. Samtliga komponerade med omsorg och noga utvalda råvaror.",
        image: "sallad"
      },
      {
        id: 4,
        header: "FÄRDIGA MACKOR",
        text:
          "Bara lite sugen? Eller på språng och behöver något lite snabbt. Då har vi underbart goda mackor gjorda på hembakat bröd från eget bageri. Mmmm…",
        image: "fralla"
      }
    ]
  }
];

const Restaurant = () => {
  const { restaurant } = useContext(RestaurantContext);
  const { header, description, image, data, giftcardtext, giftcardlink, giftcardlinktext } = RestarauntData.find(
    item => item.restaurant === restaurant
  );

  return (
    <Container>
      <Wrapper>
        <BackgroundImage src={image}>
          <Overlay>
            <Header>{header}</Header>
            <Description>{description}</Description>
            {giftcardtext && <Description>{giftcardtext}</Description>}
            {giftcardlink && <ButtonLink path={giftcardlink} label={giftcardlinktext} width="250px" />}
          </Overlay>
        </BackgroundImage>
      </Wrapper>
      {data.map(item => (
        <Wrapper key={item.id}>
          <BackgroundImage src={item.image} small>
            <Overlay small>
              <Header>{item.header}</Header>
              <Description>{item.text}</Description>
            </Overlay>
          </BackgroundImage>
        </Wrapper>
      ))}
    </Container>
  );
};

export default Restaurant;
