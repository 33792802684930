import styled from "styled-components";

const Tagline = styled.div`
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  width: 80%;
  margin-top: 2rem;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 23px;
  }

  @media (min-width: 901px) {
    font-size: 27px;
    width: 65%;
  }
`;

export default Tagline;
