import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { parseText } from "../../utils";

import { Header, Description } from "../../styles";
import { Mobile, Tablet } from "../../theme";

const Container = styled.div`
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;
`;

const Margin = styled.div`
  width: 30px;
  margin-left: 20px;
`;

const Back = styled.img`
  margin-top: 15px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobilePageHeader = ({ header, text, more, history }) => (
  <Container>
    <Margin>
      <Mobile>
        <Back
          src="./images/back16.png"
          width="16"
          height="16"
          alt="menu"
          onClick={() => history.goBack()}
        />
      </Mobile>
      <Tablet>
        <Back
          src="./images/back24.png"
          width="24"
          height="24"
          alt="menu"
          onClick={() => history.goBack()}
        />
      </Tablet>
    </Margin>
    <Content>
      <Header dark>{header}</Header>
      <Description style={{ textAlign: "center" }} dark>
        {parseText(text)}
      </Description>
      {more && (
        <Description style={{ textAlign: "center" }} dark>
          {parseText(more)}
        </Description>
      )}
    </Content>
    <Margin />
  </Container>
);

export default withRouter(MobilePageHeader);
