import React from "react";
import styled from "styled-components";
import Spinner from "react-spinkit";

const ButtonFilledContainer = styled.button`
  font-size: 24px;
  text-align: center;
  color: #fff;
  background-color: #e96565;
  border: 1px solid #d15a5a;
  padding: 0.4rem 1rem;
  width: ${props => (props.width ? props.width : "100%")};
  height: 45px;
  max-width: 500px;
  margin-top: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const ButtonFilled = props => {
  return (
    <ButtonFilledContainer {...props}>
      {props.loading ? (
        <Spinner
          name="three-bounce"
          color="white"
          style={{ paddingBottom: "2rem" }}
        />
      ) : (
        props.label
      )}
    </ButtonFilledContainer>
  );
};

export default ButtonFilled;
