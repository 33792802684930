import styled from "styled-components";

const BackgroundImage = styled.div`
	background-image: url('images/services/${(prop) => prop.src}-lg_1x.jpg');

  background-image: -webkit-image-set(
    url('images/services/${(prop) => prop.src}-lg_1x.webp') 1x,
    url('images/services/${(prop) => prop.src}-lg_2x.webp') 2x
  );
  background-image: image-set(
    url('images/services/${(prop) => prop.src}-lg_1x.jpg') 1x,
    url('images/services/${(prop) => prop.src}-lg_2x.jpg') 2x
  );

  @media (max-width: 900px) {
    background-image: url('images/services/${(prop) => prop.src}-md_2x.jpg');
    background-image: -webkit-image-set(
      url('images/services/${(prop) => prop.src}-md_1x.webp') 1x,
      url('images/services/${(prop) => prop.src}-md_2x.webp') 2x
    );
    background-image: image-set(
      url('images/services/${(prop) => prop.src}-md_1x.jpg') 1x,
      url('images/services/${(prop) => prop.src}-md_2x.jpg') 2x
    );
  }

  @media (max-width: 600px) {
    background-image: url('images/services/${(prop) => prop.src}-sm_2x.jpg');
    background-image: -webkit-image-set(
      url('images/services/${(prop) => prop.src}-sm_1x.webp') 1x,
      url('images/services/${(prop) => prop.src}-sm_2x.webp') 2x
    );
    background-image: image-set(
      url('images/services/${(prop) => prop.src}-sm_1x.jpg') 1x,
      url('images/services/${(prop) => prop.src}-sm_2x.jpg') 2x
    );
  }

	background-size: cover;
	background-position: center center;
	height: ${(prop) => (prop.height ? prop.height + "px" : "400px")};
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: 601px) and (max-width: 900px) {
    height: ${(prop) => (prop.height ? prop.height + "px" : "600px")};
	}

	@media (min-width: 901px) {
		height: ${(prop) => (prop.small ? "700px" : "1100px")};
		align-items: flex-start;
		width: 100%;
	}
`;

export default BackgroundImage;
