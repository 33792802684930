import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";
import { fetchContactData, /*parseText*/ } from "../../utils";

import { Link, /*ButtonLink, ButtonGroup, MobilePageHeader*/ } from "../../components";
import { BackgroundImage, Overlay, Header, Description } from "../../styles";
import { Mobile, Tablet, Desktop } from "../../theme";

const Container = styled.div`
  flex: 1;
  background-color: #eee;
`;

/*const Spacer = styled.div`
  padding-top: ${(prop) => (prop.space ? prop.space : "1rem")};
`;*/

const Catering = () => {
  const { restaurant } = useContext(RestaurantContext);
  const ContactData = fetchContactData(restaurant);

  /*const timestamp = Math.floor(new Date().getTime() / 1000 + 7200);

  let textNotice =
    timestamp && timestamp < 1597017600
      ? "Under v.28-32 erbjuder vi ingen catering från Ediths Kök. Kallkökets catering är dock öppen hela sommaren (fat och smörgåstårtor)"
      : "";

  let textMobile = `Välkommen till Ediths catering. Här hittar du allt från
  grilltallrikar och smörgåstårtor till bageri, hemlagad glass och
  underbara bröllopstårtor.
  
  Beställning 2 dagar före önskat leveransdatum. 

  ${textNotice}
  
  Kontaktuppgifter ${ContactData.phone}`;

  let textDesktop = `Beställning 2 dagar före önskat leveransdatum. 
  
  Kontaktuppgifter ${ContactData.phone}`;

  if (restaurant === "edi-ali-sod") {
    textMobile += ` eller ${ContactData.email}`;
    textDesktop += ` eller ${ContactData.email}`;
  }*/

  return (
    <Container>
      <Mobile>
        {/*<MobilePageHeader header="Catering" text={textMobile} />*/}
        <BackgroundImage src="chark">
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Description center>
              Välkommen till Ediths catering. Vi skräddarsyr efter dina behov, kontakta oss så berättar vi mer
            </Description>

            <Link path={`tel:${ContactData.phone}`} label={ContactData.phone} width="100" />
            <Link path={`mailto:${ContactData.email}`} label={ContactData.email} width="100" />
          </div>


          {/*<ButtonGroup column>
            <ButtonLink
              label="Till Ica:s Catering"
              path="https://www.ica.se/catering/maxi-ica-stormarknad-alingsas-1003647"
              width="240px"
            />
  </ButtonGroup>*/}
        </BackgroundImage>
      </Mobile>
      <Tablet>
        <BackgroundImage src="chark" height={800}>
          <Overlay>
            <Header>Catering</Header>

            <Description>
              Välkommen till Ediths catering. Vi skräddarsyr efter dina behov, kontakta oss så berättar vi mer
            </Description>

            <Link path={`tel:${ContactData.phone}`} label={ContactData.phone} width="100" />
            <Link path={`mailto:${ContactData.email}`} label={ContactData.email} width="100" />

            {/*timestamp && timestamp < 1597017600 && (
              <Description>
                Under v.28-32 erbjuder vi ingen catering från Ediths Kök.
                Kallkökets catering är dock öppen hela sommaren (fat och
                smörgåstårtor)
              </Description>
            )}

            <ButtonGroup>
              <ButtonLink
                label="Till Ica:s Catering"
                path="https://www.ica.se/catering/maxi-ica-stormarknad-alingsas-1003647"
                width="240px"
              />
            </ButtonGroup>
            <Spacer space="3rem" />
            <Description center>{parseText(textDesktop)}</Description>*/}
          </Overlay>
        </BackgroundImage>
      </Tablet>
      <Desktop>
        <BackgroundImage src="chark">
          <Overlay>
            <Header>Catering</Header>

            <Description>
              Välkommen till Ediths catering. Vi skräddarsyr efter dina behov, kontakta oss så berättar vi mer
            </Description>

            <Link path={`tel:${ContactData.phone}`} label={ContactData.phone} width="100" />
            <Link path={`mailto:${ContactData.email}`} label={ContactData.email} width="100" />


            {/*timestamp && timestamp < 1597017600 && (
              <Description>
                Under v.28-32 erbjuder vi ingen catering från Ediths Kök.
                Kallkökets catering är dock öppen hela sommaren <br />
                (fat och smörgåstårtor)
              </Description>
            )*/}

            {/*<ButtonGroup>
              <ButtonLink
                label="Till Ica:s Catering"
                path="https://www.ica.se/catering/maxi-ica-stormarknad-alingsas-1003647"
                width="310px"
              />
            </ButtonGroup>
            <Spacer space="6rem" />
            <Description center>{parseText(textDesktop)}</Description>*/}
          </Overlay>
        </BackgroundImage>
      </Desktop>
    </Container>
  );
};

export default Catering;
