import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";

import { BackgroundImage, Overlay, Header, Description } from "../../styles";

const Container = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`
  margin-bottom: 3px;
`;

const CafeData = [
  {
    restaurant: "edi-ali-hem",
    header: "Glass & Café",
    image: "glass",
    description:
      "Sugen på glass eller en härlig fika? Då har du kommit rätt. Är du i glasstagen så har vi en himmelskt god, hemgjord Italienska glass. Är det kaffe och tårta eller kaka eller paj eller… Ja, vi har det mesta du är sugen på. Vi ses!",
    data: [
      {
        id: 1,
        header: "HEMGJORD GLASS",
        text:
          "Gelato. Bara ordet glass på Italienska får det att vattnas i munnen. Vi åkte till Italien och lärde oss göra riktig italiensk glass, så att du inte behöver åka dit varje gång du blir sugen. Välkommen att stilla din längtan.",
        image: "glass3"
      },
      {
        id: 2,
        header: "HÄRLIGA BAKVERK",
        text:
          "Vårt samarbete med ICA Maxi bageriet gör att vi kan erbjuda våra kunder fantastiska bakverk. Samtliga gjorda med kärlek och hjärta av Maxi´s proffesionella konditorer. Så oavsett om du är sugen på en liten bakelse eller vill beställa din bröllopstårta så kan vi hjälpa dig. Välkommen.",
        image: "bakverk"
      },
      {
        id: 3,
        header: "Fika",
        text:
          "Vi vore inte ett genuint Alingsås café om vi inte kunde erbjuda en härlig fika med allt vad det innebär. Hos oss får du vad du längtar efter, om det så bara är en slät kopp. Välkommen in.",
        image: "bullar"
      }
    ]
  },
  {
    restaurant: "edi-ali-sod",
    header: "Café",
    image: "bullar",
    description:
      "Sugen på en härlig fika? Då har du kommit rätt. Är det kaffe och kaka eller paj eller… Ja, vi har det mesta du är sugen på. Vi ses!",
    data: [
      /*{
        id: 2,
        header: "HÄRLIGA BAKVERK",
        text:
          "Vårt samarbete med ICA Maxi bageriet gör att vi kan erbjuda våra kunder fantastiska bakverk. Samtliga gjorda med kärlek och hjärta av Maxi´s proffesionella konditorer. Så oavsett om du är sugen på en liten bakelse eller vill beställa din bröllopstårta så kan vi hjälpa dig. Välkommen.",
        image: "bakverk"
      },
      {
        id: 3,
        header: "Fika",
        text:
          "Vi vore inte ett genuint Alingsås café om vi inte kunde erbjuda en härlig fika med allt vad det innebär. Hos oss får du vad du längtar efter, om det så bara är en slät kopp. Välkommen in.",
        image: "fika"
      }*/
    ]
  }
];

const Cafe = () => {
  const { restaurant } = useContext(RestaurantContext);
  const { header, description, image, data } = CafeData.find(
    item => item.restaurant === restaurant
  );

  return (
    <Container>
      <Wrapper>
        <BackgroundImage src={image}>
          <Overlay>
            <Header>{header}</Header>
            <Description>{description}</Description>
          </Overlay>
        </BackgroundImage>
      </Wrapper>
      {data.map(item => (
        <Wrapper key={item.id}>
          <BackgroundImage src={item.image} small>
            <Overlay small>
              <Header>{item.header}</Header>
              <Description>{item.text}</Description>
            </Overlay>
          </BackgroundImage>
        </Wrapper>
      ))}
    </Container>
  );
};

export default Cafe;
