import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "./App";
import "core-js";

Sentry.init({
  dsn: "https://061be96be0b5456a9264c0637769b77c@sentry.io/2296745",
});

ReactDOM.render(<App />, document.getElementById("root"));
