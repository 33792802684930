import React from "react";
import styled from "styled-components";

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: ${(prop) => (prop.size === "large" ? "1rem" : 0)};
  margin-right: ${(prop) => (prop.size === "large" ? "1rem" : 0)};
`;

const QuantityButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #e96565;
  border: 2px solid #e96565;
  cursor: pointer;

  font-size: ${(prop) => (prop.size === "large" ? "20px" : "12px")};
  width: ${(prop) => (prop.size === "large" ? "42px" : "24px")};
  height: ${(prop) => (prop.size === "large" ? "42px" : "24px")};

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
`;

const QuantityNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #e96565;
  border-style: solid;
  border-color: #e96565;
  border-width: 2px 0px;

  font-size: ${(prop) => (prop.size === "large" ? "20px" : "12px")};
  width: ${(prop) => (prop.size === "large" ? "42px" : "24px")};
  height: ${(prop) => (prop.size === "large" ? "42px" : "24px")};

  &p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`;

const Quantity = ({
  quantity,
  quantityMin,
  quantityMax,
  setQuantity,
  size = "large",
}) => {
  const changeQuantity = (qty) => {
    if (quantity + qty < 1) return;
    if (quantity + qty < quantityMin) return;
    if (quantity + qty > quantityMax) return;

    setQuantity(quantity + qty);
  };

  return (
    <QuantityContainer size={size}>
      <QuantityButton onClick={() => changeQuantity(-1)} size={size}>
        &minus;
      </QuantityButton>
      <QuantityNumber size={size}>
        <p>{quantity}</p>
      </QuantityNumber>
      <QuantityButton onClick={() => changeQuantity(1)} size={size}>
        {" "}
        +
      </QuantityButton>
    </QuantityContainer>
  );
};

export default Quantity;
