import styled from "styled-components";

const OrnamentMobileTop = styled.div`
  background-image: url("images/ornament-mobile.png");
  background-repeat: none;
  width: 262px;
  height: 22px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const OrnamentMobileBottom = styled.div`
  background-image: url("images/ornament-mobile.png");
  background-repeat: none;
  width: 262px;
  height: 22px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
`;

const OrnamentDesktopTop = styled.div`
  background-image: url("images/ornament-desktop.png");
  background-repeat: none;
  width: 555px;
  height: 46px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const OrnamentDesktopBottom = styled.div`
  background-image: url("images/ornament-desktop.png");
  background-repeat: none;
  width: 555px;
  height: 46px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
`;

export {
  OrnamentMobileTop,
  OrnamentMobileBottom,
  OrnamentDesktopTop,
  OrnamentDesktopBottom
};
