import styled, { injectGlobal } from "styled-components";

export const theme = {
  grey: "#666666"
};

injectGlobal`
  @font-face {
    font-family: "Timber";
    font-display: auto;
    src: url("./fonts/Timber.ttf");
  }
  @font-face {
    font-family: "Avenir";
    font-display: auto;
    src: url("./fonts/Avenir-Light.woff");
  }

  * {
    margin: 0;
    padding: 0;
    font-family: Avenir, sans-serif;
  }

  @media (max-width: 600px) {
    body {
      -webkit-user-select: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
    }
  }
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Tablet = styled.div`
  display: none;

  @media (min-width: 601px) and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Desktop = styled.div`
  display: none;

  @media (min-width: 901px) {
    display: flex;
    flex-direction: ${prop => (prop.column ? "column" : "row")};
    flex: 1;
  }
`;
