export const Takeaway = {
  url: "https://hey.hn/HVTGJ",
  openHours: [
    {
      id: 1,
      name: "Mån",
      open: "11.00",
      close: "19:00",
    },
    {
      id: 2,
      name: "Tis",
      open: "11.00",
      close: "19:00",
    },
    {
      id: 3,
      name: "Ons",
      open: "11:00",
      close: "19:00",
    },
    {
      id: 4,
      name: "Tor",
      open: "11.00",
      close: "19:00",
    },
    {
      id: 5,
      name: "Fre",
      open: "09.00",
      close: "19:00",
    },
    {
      id: 6,
      name: "Lör",
      open: "12.00",
      close: "17:00",
    },
    {
      id: 7,
      name: "Sön",
      open: "12.00",
      close: "17:00",
    },
  ],
};
