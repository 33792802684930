import React from 'react';
import styled from 'styled-components';

import { Mobile, Tablet, Desktop } from '../../theme';

const Spacer = styled.div`
  background-image: url('./images/checked-border-${props => props.size}.png');
  background-repeat: repeat-y round;
	width: 100%;
  height: ${props => props.size}px;
`;

const CheckedSpacer = () => (
  <div>
    <Mobile>
      <Spacer size="20"/>
    </Mobile>
    <Tablet>
      <Spacer size="44"/>
    </Tablet>
    <Desktop>
      <Spacer size="44"/>
    </Desktop>
  </div>
);

export default CheckedSpacer;