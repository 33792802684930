import React from "react";
import styled from "styled-components";

import { BackgroundImage, Header, SubHeader2, Description } from "../../styles";
import { Mobile, Tablet, Desktop } from "../../theme";

import { Landing } from "../../components";

const Container = styled.div`
  flex: 1;
`;

const Overlay = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);

  @media (min-width: 901px) {
    flex: unset;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin: 0;
    width: 800px;
  }
`;

const Breakfast = () => (
  <Container>
    <Mobile>
      <BackgroundImage src="frukost" height="700">
        <Overlay>
          <Header>Frukost</Header>
          <Description center>
            <SubHeader2>Ediths Frukostbuffé</SubHeader2>
            Serveras fredagar mellan 07-10
            <br />
            <br />
            Välkommna!
          </Description>
        </Overlay>
      </BackgroundImage>
    </Mobile>
    <Tablet>
      <BackgroundImage src="frukost" height="700">
        <Overlay>
          <Header>Frukost</Header>
          <Description center>
            <SubHeader2>Ediths Frukostbuffé</SubHeader2>
            Serveras fredagar mellan 07-10
            <br />
            <br />
            Välkommna!
          </Description>
        </Overlay>
      </BackgroundImage>
    </Tablet>
    <Desktop>
      <Landing />
    </Desktop>
  </Container>
);

export default Breakfast;
