import React from "react";
import styled from "styled-components";

import { ButtonPrimary } from "../../components";

const Container = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  @media (min-width: 601px) and (max-width: 900px) {
    margin: 4rem;
  }

  @media (min-width: 901px) {
    margin: 6rem;
  }
`;

const Header = styled.div`
  font-family: "Timber";
  font-size: 45px;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-transform: uppercase;
  text-align: center;
  line-height: 1;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 65px;
  }

  @media (min-width: 901px) {
    font-size: 90px;
  }
`;

const SubHeader = styled.div`
  font-size: 22px;
  margin-bottom: 0.5rem;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.8)" : "#fff")};

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
  }

  @media (min-width: 901px) {
    font-size: 34px;
  }
`;

const SubHeader2 = styled.div`
  font-size: 19px;
  margin-bottom: 0.5rem;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.8)" : "#fff")};

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 23px;
  }

  @media (min-width: 901px) {
    font-size: 28px;
  }
`;

const Text = styled.div`
  font-size: 17px;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: center;
  text-align: ${(prop) => (prop.center ? "center" : "left")};

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
  }

  @media (min-width: 901px) {
    font-size: 25px;
  }
`;

const Spacer = styled.div`
  height: 2rem;

  @media (min-width: 901px) {
    height: 3rem;
  }
`;

const DeliveryConditions = () => {
  return (
    <Container>
      <Wrapper>
        <Header dark>Leveransvillkor</Header>
        <Spacer />
        <Text dark center>
          Vi ber dig att läsa våra allmäna villkor nedan innan du beställer
          produkter från vår hemsida. När du beställer produkter via telefon
          godkänner du de allmäna villkoren. Dessa villkor gäller dig som
          använder vår beställningtjänst på www.edithskok.se alt. Via telefon.
          Organisationsnummer 556918-8559 Alingsås stormarknad AB.
        </Text>
        <Spacer />

        <ButtonPrimary label="Gå till varukorg" path="/varukorg" />
        <Spacer />

        <SubHeader dark>1. Allmänt</SubHeader>
        <Text dark>
          Dessa Allmänna kundvillkor gäller mellan konsumenten (”Du”) och
          ICA-butiken (”ICA Maxi Alingsås”) vid försäljning av produkter genom
          Butikens e- handelsbutik (”edithskok.se”) Genom att kryssa i avsedd
          ruta i Nätbutiken godkänner Du innehållet i dessa villkor samt intygar
          riktigheten i de uppgifter som lämnas. För att handla i Nätbutiken
          måste Du vara myndig och ha rätt att ingå avtal.
        </Text>
        <Spacer />
        <SubHeader dark>
          2. Orderbekräftelse, ändring och avbokning av order
        </SubHeader>
        <Spacer />

        <SubHeader2 dark>2.1 Orderbekräftelse</SubHeader2>
        <Text dark>
          När Butiken har mottagit Din order skickas en orderbekräftelse per
          e-post till Dig.
        </Text>
        <Spacer />
        <SubHeader2 dark>2.2 Ändring och avbokning av order</SubHeader2>
        <Text dark>
          Ändring eller/och avbokning av order sker via telefon via angivna
          telefonnummer på hemsidan www.edithskok.se
        </Text>
        <Spacer />

        <SubHeader dark>3. Varor</SubHeader>
        <Spacer />
        <SubHeader2 dark>3.1 Produktinformation</SubHeader2>
        <Text dark>
          Produktinformation kring enskild vara presenteras i Nätbutiken i
          anslutning till köpet av varan.
        </Text>
        <Spacer />

        <SubHeader dark>4. Priser</SubHeader>
        <Text dark>
          Priset för de varor som beställts är det pris som gäller på dagen för
          Din order med reservation för eventuella tryckfel eller andra
          felaktigheter i Nätbutikens erbjudande. Alla priser anges inklusive
          moms.
        </Text>
        <Spacer />
        <SubHeader2 dark>4.1 Varor med viktpris</SubHeader2>
        <Text dark>
          För varor som säljs styckvis eller i lösvikt, där priset baseras på
          dess vikt (”viktvara”), anges viktvarans pris per viktenhet. Exakt
          pris fastställs när ordern har plockats och viktvaran vägts, varvid
          priset korrigeras utifrån den faktiska vikten. Du betalar alltid för
          varans faktiska vikt och ambitionen är att plocka en mängd som
          motsvarar ordern.
        </Text>
        <Spacer />

        <SubHeader dark>5. Betalning</SubHeader>
        <Text dark>
          Betalning sker efter att produkten är upphämtad i butikens kassor.
        </Text>
        <Spacer />

        <SubHeader dark>6. Leverans</SubHeader>
        <Text dark>
          Butiken kan erbjuda leverans genom upphämtning i Butik. Du eller
          behörig person uppger då det namn som ordern är lagd i. Dina varor
          anses levererade då Du, eller behörig person, hämtat dem i Butik.
        </Text>
        <Spacer />
        <SubHeader2 dark>6.1 Upphämtning i butik</SubHeader2>
        <Text dark>
          Vid upphämtning i Butik ska detta ske inom den dag du angivit vid
          beställning.
        </Text>
        <Spacer />
        <SubHeader2 dark>6.2 Ej mottagen eller uthämtad vara</SubHeader2>
        <Text dark>
          Om upphämtning eller mottagande av varor inte kan ske inom valt
          tidsintervall är Du skyldig att ta kontakt med oss. Kontaktuppgifter
          finns i Nätbutiken eller på orderbekräftelsen. Om Du inte kan hämta
          Dina varor inom valt tidsintervall har Butiken rätt att debitera hela
          orderns värde. Butiken kan efter överenskommelse förvara ej uthämtade
          varor under en skälig tid, i mån av utrymme och varornas hållbarhet.
        </Text>
        <Spacer />

        <SubHeader dark>7. Reklamation, retur och ångerrätt</SubHeader>
        <Spacer />
        <SubHeader2 dark>7.1 Reklamation och retur</SubHeader2>
        <Text dark>
          Om det enligt bestämmelserna i Konsumentköplagen (SFS 1990:932) är fel
          på en vara så har Du rätt att reklamera den inom tre år på grund av
          felet. Reklamation ska ske snarast efter att felet upptäcktes. Om en
          vara är felaktig kommer Du att krediteras motsvarande varans värde
          alternativt få felet avhjälpt Kontaktuppgifter finns i Nätbutiken och
          på orderbekräftelsen.
        </Text>
        <Spacer />
        <SubHeader2 dark>7.2 Ångerrätt</SubHeader2>
        <Text dark>
          Enligt lagen om distansavtal och avtal utanför affärslokaler (SFS
          2005:59) har du rätt att ångra Ditt köp inom 14 dagar från leverans av
          varan (”Ångerfristen”). Sådan ångerrätt gäller dock inte för
          exempelvis livsmedel. Har du frågor eller vill du göra en reklamation
          ber vi dig att ringa oss.
        </Text>
        <Spacer />

        <SubHeader dark>8. Övrigt</SubHeader>
        <Spacer />
        <SubHeader2 dark>8.1 Kontakt</SubHeader2>
        <Text dark>
          Ediths kök <a href="tel:004632278455">0322-78455</a>
          <br />
          Kallkök <a href="tel:004632278449">0322-78449</a>
          <br />
          Varmköket <a href="tel:004632278447">0322-78447</a>
          <br />
          Bageri <a href="tel:004632278409">0322-78409</a>
        </Text>
        <Spacer />
        <SubHeader2 dark>8.2 Oförutsedda händelser</SubHeader2>
        <Text dark>
          Vid försenad eller utebliven leverans, som inte beror på Dig, kan
          Butiken enligt Konsumentköplagen (SFS 1990:932) under vissa
          omständigheter befrias från att fullgöra leveransen.
        </Text>
        <Spacer />
        <SubHeader2 dark>8.3 Tvist</SubHeader2>
        <Text dark>
          Om Du och Butiken inte kommer överens kan Du anmäla ärendet till
          Allmänna reklamationsnämnden (ARN), Box 174, 101 23
          Stockholm, www.arn.se som kostnadsfritt prövar tvister mellan
          konsumenter och näringsidkare. Butiken medverkar i
          tvistlösningsförandet och följer ARN:s rekommendationer.
        </Text>
        <Spacer />
        <ButtonPrimary label="Gå till varukorg" path="/varukorg" />
      </Wrapper>
    </Container>
  );
};

export default DeliveryConditions;
