import React from "react";
import styled from "styled-components";

const Favorite = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  svg {
    path {
      fill: ${props =>
        props.selected ? "#e96565" : props.light ? "#e96565" : "#ffffff"};
    }
  }
`;

const Heart = ({ size, selected, onClick, style, light }) => (
  <Favorite onClick={onClick} selected={selected} style={style} light={light}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={selected ? "0 0 59.945 59.945" : "0 0 455 455"}
      width={size}
      height={size}
    >
      {selected ? (
        <path d="M58.598,12.729C56.757,8.025,53.242,4.418,48.7,2.571c-1.964-0.798-3.953-1.203-5.91-1.203  c-6.509,0-11.063,4.358-12.84,6.401c-1.768-2.05-6.302-6.426-12.809-6.426c-1.951,0-3.934,0.404-5.895,1.201  C6.704,4.391,3.188,7.998,1.347,12.701c-1.964,5.019-1.773,10.707,0.525,15.608c3.263,6.957,10.681,17.788,27.49,29.872l0.583,0.42  l0.583-0.42c16.892-12.144,24.302-22.934,27.543-29.847C60.371,23.435,60.562,17.747,58.598,12.729z" />
      ) : (
        <path d="M326.632,10.346c-38.733,0-74.991,17.537-99.132,46.92c-24.141-29.384-60.398-46.92-99.132-46.92  C57.586,10.346,0,67.931,0,138.714c0,55.426,33.05,119.535,98.23,190.546c50.161,54.647,104.728,96.959,120.257,108.626l9.01,6.769  l9.01-6.768c15.529-11.667,70.098-53.978,120.26-108.625C421.949,258.251,455,194.141,455,138.714  C455,67.931,397.414,10.346,326.632,10.346z M334.666,308.974c-41.259,44.948-85.648,81.283-107.169,98.029  c-21.52-16.746-65.907-53.082-107.166-98.03C61.236,244.592,30,185.717,30,138.714c0-54.24,44.128-98.368,98.368-98.368  c35.694,0,68.652,19.454,86.013,50.771l13.119,23.666l13.119-23.666c17.36-31.316,50.318-50.771,86.013-50.771  c54.24,0,98.368,44.127,98.368,98.368C425,185.719,393.763,244.594,334.666,308.974z" />
      )}
    </svg>
  </Favorite>
);

export default Heart;
