import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { toast } from "react-toastify";

import {
  ButtonPrimary,
  CartForm,
  MobilePageHeader,
  Quantity,
} from "../../components";
import { Mobile, Tablet, Desktop } from "../../theme";
import { Header, Description } from "../../styles";

import { RestaurantContext } from "../../context";

import { GET_CART } from "../../graphql/query";
import {
  REMOVE_FROM_CART_MUTATION,
  CHANGE_ITEM_QUANTITY_MUTATION,
} from "../../graphql/mutation";

import { formatPrice } from "../../utils";

const URL = process.env.REACT_APP_STORAGE_URL;
const URL_VERSION = process.env.REACT_APP_STORAGE_URL_VERSION;
const IMAGE_QUAL = "w_200,f_auto,q_auto,dpr_2.0";

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 980px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem 1rem 0rem 1rem;
`;

const Spacer = styled.div`
  padding-top: 3rem;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  background-color: #fafafa;
`;

// prettier-ignore
const Image = styled.div`
  height: 80px;
  width: 80px;
  margin-right: 0.5rem;
  background-color: #f1f1f1;
  background-size: cover;
	background-position: center center;

  background-image: url('${(prop) => `${URL}/${IMAGE_QUAL}/${URL_VERSION}/products/${prop.src}.jpg`}');
`;

const Middle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Timber";
  font-size: 30px;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 28px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Total = styled.div`
  font-family: "Timber";
  font-size: 30px;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 28px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const TotalVat = styled.div`
  font-family: "Timber";
  font-size: 20px;
  text-transform: uppercase;
  color: grey;

  @media (max-width: 900px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Tag = styled.span`
  background-color: #aee595;
  border: 1px solid #93c17d;
  border-radius: 4px;
  padding: 0px 4px;
  margin-right: 4px;
  text-transform: uppercase;
  font-size: 10px;
`;

const Close = styled.span`
  background: #e96565;
  opacity: 0.8;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 0px;
  margin-left: -20px;
  min-width: 15px;
  padding: 1px;
  position: absolute;
  text-align: center;
`;

const Cart = () => {
  const { cartId, setCartId } = useContext(RestaurantContext);
  const [cart, setCart] = useState(null);

  const { loading, data, error } = useQuery(GET_CART, {
    variables: { id: cartId },
  });

  if (loading)
    return (
      <Spinner
        name="three-bounce"
        color="rgba(0, 0, 0, 0.6)"
        style={{ paddingBottom: "2rem" }}
      />
    );

  if (error) toast.error(`Något gick fel: ${error.message}`);

  if (data) {
    if (cart !== data.cart) {
      setCart(data.cart);
    }
  }

  return (
    <div>
      {cart && cart.items && cart.items.length > 0 ? (
        <Container>
          <Mobile>
            <MobilePageHeader
              header="Varukorg"
              text="Hej, vad kul att du hittat några varor i vårt stora utbud av
            catering. Kontrollera att dina val stämmer och fyll i dina kontaktuppgifter, 
            sen är du redo att lägga din beställning. Hoppas det smakar!"
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CartList cart={cart} />
              <Spacer />
              <CartForm cart={cart} handleRemoveCart={setCartId} />
              <Spacer />
            </div>
          </Mobile>
          <Tablet>
            <MobilePageHeader
              header="Varukorg"
              text="Hej, vad kul att du hittat några varor i vårt stora utbud av
            catering. Kontrollera att dina val stämmer och fyll i dina kontaktuppgifter, 
            sen är du redo att lägga din beställning. Hoppas det smakar!"
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CartList cart={cart} />
              <Spacer />
              <CartForm cart={cart} handleRemoveCart={setCartId} />
              <Spacer />
            </div>
          </Tablet>
          <Desktop>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "5rem",
              }}
            >
              <Header dark>Varukorg</Header>
              <Description dark>
                Hej, vad kul att du hittat några varor i vårt stora utbud av
                catering. Kontrollera att dina val stämmer och fyll i dina
                kontaktuppgifter, sen är du redo att lägga din beställning.
                Hoppas det smakar!
              </Description>
              <Spacer />
              <CartList cart={cart} />
              <Spacer />
              <CartForm cart={cart} handleRemoveCart={setCartId} />
              <Spacer />
            </div>
          </Desktop>
        </Container>
      ) : (
        <Container>
          <Mobile>
            <MobilePageHeader
              header="Varukorg"
              text="Hej! Det verkar som att du inte har några varor i din varukorg ännu."
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ButtonPrimary label="Gå till Catering?" path="/catering" />
              <Spacer />
            </div>
          </Mobile>
          <Tablet>
            <MobilePageHeader
              header="Varukorg"
              text="Hej! Det verkar som att du inte har några varor i din varukorg ännu."
            />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ButtonPrimary label="Gå till Catering?" path="/catering" />
              <Spacer />
            </div>
          </Tablet>
          <Desktop>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "5rem",
              }}
            >
              <Header dark>Varukorg</Header>
              <Description dark center>
                Hej! Det verkar som att du inte har några varor i din varukorg
                ännu.
              </Description>
              <ButtonPrimary label="Gå till Catering?" path="/catering" />
              <Spacer />
            </div>
          </Desktop>
        </Container>
      )}
    </div>
  );
};

const CartList = ({ cart }) => (
  <Wrapper>
    {cart
      ? cart.items.map((item) => {
          return <CartItem key={item.id} cartId={cart.id} item={item} />;
        })
      : null}
    <TotalRow>
      <Total>Totalt: {cart && formatPrice(cart.total)}</Total>
    </TotalRow>
    <TotalRow>
      <TotalVat>Varav moms: {cart && formatPrice(cart.totalVat)}</TotalVat>
    </TotalRow>
  </Wrapper>
);

const CartItem = ({ cartId, item }) => {
  const [removeFromCartMutation] = useMutation(REMOVE_FROM_CART_MUTATION);
  const [changeItemQuantityMutation] = useMutation(
    CHANGE_ITEM_QUANTITY_MUTATION
  );

  const removeFromCart = async (cartId, itemId) => {
    if (cartId && itemId) {
      try {
        await removeFromCartMutation({
          variables: { id: cartId, itemId: itemId },
          refetchQueries: ["GET_CART"],
        });

        toast.error(`${item.name} borttagen ur varukorg`);
      } catch (err) {
        toast.error(`Något gick fel ${err.message}`);
      }
    }
  };

  const setQuantity = async (quantity) => {
    if (cartId && item.id && quantity) {
      try {
        await changeItemQuantityMutation({
          variables: { id: cartId, itemId: item.id, quantity: quantity },
          refetchQueries: ["GET_CART"],
        });
      } catch (err) {
        toast.error(`Något gick fel ${err.message}`);
      }
    }
  };

  const tagReplacer = (tag) => {
    return <Tag>{tag}</Tag>;
  };

  return (
    <Row>
      <Image src={item.image} />
      <Middle>
        <Name>{item.name}</Name>
        {item.option && <div>{item.option}</div>}
        {item.tag && item.tag.length > 0 && (
          <div style={{ marginBottom: "0.2rem" }}>
            {item.tag.map((tag) => (
              <span key={tag}>{tagReplacer(tag)}</span>
            ))}
          </div>
        )}
        {formatPrice(item.priceVat)}
      </Middle>
      <Right
        style={{ flexDirection: "column", justifyContent: "space-between" }}
      >
        <button onClick={() => removeFromCart(cartId, item.id)}>
          <Close>x</Close>
        </button>
        <Quantity
          quantity={item.quantity}
          setQuantity={setQuantity}
          size="small"
        />
      </Right>
    </Row>
  );
};

export default Cart;
