import React from "react";
import styled from "styled-components";

import { Lunch } from "../../components";

const Container = styled.div``;

const BackgroundImage = styled.div`
  /*background-image: url("images/services/lunch-sm_2x.jpg");*/

  background-image: url('images/services/${prop => prop.src}-lg_1x.jpg');

  background-image: -webkit-image-set(
    url('images/services/${prop => prop.src}-lg_1x.webp') 1x,
    url('images/services/${prop => prop.src}-lg_2x.webp') 2x
  );
  background-image: image-set(
    url('images/services/${prop => prop.src}-lg_1x.jpg') 1x,
    url('images/services/${prop => prop.src}-lg_2x.jpg') 2x
  );

  @media (max-width: 900px) {
    background-image: url('images/services/${prop => prop.src}-md_2x.jpg');
    background-image: -webkit-image-set(
      url('images/services/${prop => prop.src}-md_1x.webp') 1x,
      url('images/services/${prop => prop.src}-md_2x.webp') 2x
    );
    background-image: image-set(
      url('images/services/${prop => prop.src}-md_1x.jpg') 1x,
      url('images/services/${prop => prop.src}-md_2x.jpg') 2x
    );
  }

  @media (max-width: 600px) {
    background-image: url('images/services/${prop => prop.src}-sm_2x.jpg');
    background-image: -webkit-image-set(
      url('images/services/${prop => prop.src}-sm_1x.webp') 1x,
      url('images/services/${prop => prop.src}-sm_2x.webp') 2x
    );
    background-image: image-set(
      url('images/services/${prop => prop.src}-sm_1x.jpg') 1x,
      url('images/services/${prop => prop.src}-sm_2x.jpg') 2x
    );
  }

  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
  padding: 3rem 1rem 1rem 1rem;
  background-color: rgba(0, 0, 0, 0.6);
`;

const LunchPage = () => (
  <Container>
    <BackgroundImage src="lunch">
      <Overlay>
        <Lunch />
      </Overlay>
    </BackgroundImage>
  </Container>
);

export default LunchPage;
