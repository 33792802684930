import React, { useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import getISOWeek from "date-fns/getISOWeek";
import getISODay from "date-fns/getISODay";
import Spinner from "react-spinkit";

import { RestaurantContext } from "../../context";

import { Header, Description } from "../../styles";
import { Diamond } from "../../components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 850px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  padding: 2rem;
`;

const SubHeader = styled.div`
  font-size: 24px;
  line-height: 1.4;
  color: white;
  text-align: center;

  @media (max-width: 600px) {
    ${(prop) => (prop.width ? "width: " + prop.width + "px;" : "")}
  }

  & > span {
    font-size: 28px;
  }
`;

const Week = styled.div`
  font-family: "Timber";
  font-size: 32px;
  font-weight: bold;
  color: white;
  border: 2px solid white;
  max-width: 60px;

  margin-top: 1.5rem;
  padding: 0.2rem 0rem 0.2rem 0.5rem;

  @media (min-width: 901px) {
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @media (min-width: 901px) {
    flex-direction: row;
  }
`;

const WeekContainer = styled.div`
  padding-right: 2rem;
`;

const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Day = styled.div`
  font-family: "Timber";
  font-size: 30px;
  font-weight: 500;
  padding-top: 1.5rem;
  color: white;
`;

const FoodRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Food = styled.div`
  font-size: 17px;
  line-height: 1.4;
  color: white;
`;

const Small = styled.div`
  font-size: 14px;
  text-align: center;
  padding-top: 1rem;
  color: white;
`;

const Lunch = () => {
  const { restaurant, restaurantData } = useContext(RestaurantContext);
  const {
    lunchOpenHoursWeekdays,
    lunchOpenHoursWeekends,
    lunchPrice,
    lunchCouponPrice,
  } = restaurantData;

  let year = new Date().getFullYear();
  let week = getISOWeek(new Date());
  let day = getISODay(new Date());
  let dayIdPrev = -1;

  if (day > 5) week = week + 1;
  if (week > 53) week = 1;

  const GET_LUNCH = gql`
    query Lunch($year: Int!, $week: Int!, $slug: String) {
      lunch(year: $year, week: $week, slug: $slug) {
        dishes {
          id
          dayId
          day
          name
        }
      }
    }
  `;

  return (
    <Container>
      <Header style={{ whiteSpace: "nowrap", width: "100%" }}>
        Veckans Dagens
      </Header>
      <Query query={GET_LUNCH} variables={{ year, week, slug: restaurant }}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <LoadingWrapper>
                <Spinner name="three-bounce" color="white" />
              </LoadingWrapper>
            );
          }

          if (error) {
            return (
              <Wrapper>
                <Day>Något gick fel</Day>
                <Description>
                  Ibland blir det inte alltid som man tänkt sig. Vi lovar att
                  bättra oss :-)
                </Description>
              </Wrapper>
            );
          }

          if (!data.lunch) {
            return (
              <Container>
                {lunchPrice && (
                  <SubHeader>
                    Dagens lunch <span>{lunchPrice}:-</span> | Lunchhäfte{" "}
                    <span>{lunchCouponPrice}:-</span>
                  </SubHeader>
                )}
                <div>
                  <Week>V.{week}</Week>
                  <Day>Oj!</Day>
                  <Description>
                    Du var hungrig tidigt idag. Håll ut en liten stund till så
                    kommer veckans meny snart att laddas upp.
                  </Description>
                </div>
              </Container>
            );
          } else {
            return (
              <Container>
                {lunchPrice && (
                  <SubHeader>
                    Dagens lunch <span>{lunchPrice}:-</span> | Lunchhäfte{" "}
                    <span>{lunchCouponPrice}:-</span>
                  </SubHeader>
                )}
                {lunchOpenHoursWeekdays && (
                  <SubHeader>
                    Vardagar <span>{lunchOpenHoursWeekdays}</span>
                    {lunchOpenHoursWeekends && (
                      <span> | Helg {lunchOpenHoursWeekends}</span>
                    )}
                  </SubHeader>
                )}
                <MenuContainer>
                  <WeekContainer>
                    <Week>V.{week}</Week>
                  </WeekContainer>
                  <DayContainer>
                    {data.lunch.dishes.map((dish) => {
                      if (dayIdPrev === dish.dayId) {
                        dayIdPrev = dish.dayId;
                        return (
                          <FoodRow key={dish.id}>
                            <Diamond />
                            <Food>{dish.name}</Food>
                          </FoodRow>
                        );
                      } else {
                        dayIdPrev = dish.dayId;
                        return (
                          <div key={dish.id}>
                            <Day>{dish.day}</Day>
                            <FoodRow>
                              <Diamond />
                              <Food>{dish.name}</Food>
                            </FoodRow>
                          </div>
                        );
                      }
                    })}
                    <Small>
                      Innehållsdeklaration, ring för mer information.
                    </Small>
                  </DayContainer>
                </MenuContainer>
              </Container>
            );
          }
        }}
      </Query>
    </Container>
  );
};

export default Lunch;
