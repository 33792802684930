import React from 'react';
import styled from 'styled-components';

const ButtonGroupContainer = styled.div`
	display: flex;
	flex-direction: ${(prop) => (prop.column ? 'column' : 'row')};
	margin-top: 1rem;
`;

const ButtonGroup = ({ children, column }) => <ButtonGroupContainer column={column}>{children}</ButtonGroupContainer>;

export default ButtonGroup;
