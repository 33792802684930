import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Mutation } from "react-apollo";
import styled from "styled-components";

import DatePicker, { registerLocale } from "react-datepicker";
import { Checkbox } from "pretty-checkbox-react";
import sv from "date-fns/locale/sv";
import addDays from "date-fns/addDays";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import setSeconds from "date-fns/setSeconds";
import differenceInHours from "date-fns/differenceInHours";

import { CREATE_ORDER_MUTATION } from "../../graphql/mutation";

import "react-datepicker/dist/react-datepicker.min.css";
import "pretty-checkbox/dist/pretty-checkbox.min.css";
import "./CartForm.css";

import { ButtonFilled } from "../../components";

registerLocale("sv", sv);

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  max-width: 980px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Col = styled.div``;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 20px;
`;

const Label = styled.label`
  display: block;
  margin: 0 0 10px;
  color: $gray;
  font-size: 12px;
  font-weight: $semibold;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const Input = styled.input`
  outline: none;
  display: block;
  background: #f1f1f1;
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 20px;
  color: #000;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
  transition: 0.3s ease;

  border: ${(prop) => (prop.error ? "1px solid red" : 0)};
`;

const TextArea = styled.textarea`
  outline: none;
  display: block;
  background: #f1f1f1;
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 20px;
  color: #000;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
  transition: 0.3s ease;

  border: ${(prop) => (prop.error ? "1px solid red" : 0)};
`;

const Spacer = styled.div`
  padding-top: 3rem;
`;

const InputError = styled.div`
  color: red;
`;

class CartForm extends Component {
  state = {
    cart: null,
    name: "",
    email: "",
    mobile: "",
    comment: "",
    showComment: false,
    showCommentCount: 0,
    accept: false,
    minDate: addDays(new Date(), 2),
    deliveryDate: addDays(
      setHours(setMinutes(setSeconds(new Date(), 0), 0), 16),
      2
    ),
    loading: false,
    errors: {},
  };

  handleChangeDeliveryDate = (date) => {
    this.setState({
      deliveryDate: setMinutes(setSeconds(date, 0), 0),
    });
  };

  handleInputChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleCheckboxChange = ({ target: { id, checked } }) => {
    this.setState({ [id]: checked });
  };

  handleIncrementShowComment = () => {
    if (this.state.showCommentCount >= 3 && !this.state.showComment) {
      this.setState({ showComment: true });
    } else {
      this.setState({ showCommentCount: this.state.showCommentCount + 1 });
    }
  };

  handleValidation = () => {
    const { name, mobile, deliveryDate, accept } = this.state;

    //reset errors
    let errors = {};

    //check deliveryDate - min 2 days (31h, allow to order 07:00 at day 2)
    if (!deliveryDate) {
      errors = { ...errors, deliveryDate: "Välj ett leveransdatum" };
    }

    if (deliveryDate && differenceInHours(deliveryDate, new Date()) < 31) {
      const hourDiff = differenceInHours(deliveryDate, new Date());

      errors = {
        ...errors,
        deliveryDate: `Det är mindre än 2 dagar kvar till valt leveransdatum (${hourDiff} timmar). Välj ett seanre datum.`,
      };
    }

    //check name
    if (!name || name.length < 3) {
      errors = { ...errors, name: "Fyll i ditt namn, minst tre tecken" };
    }

    //check email
    /*if (!email) {
      errors = { ...errors, email: "Fyll i din e-post" };
    }*/

    //chekc mobile
    if (!mobile || mobile.length < 10) {
      errors = {
        ...errors,
        mobile: "Fyll i ditt mobilnummer, minst 10 siffror",
      };
    }

    //check accept
    if (!accept) {
      errors = {
        ...errors,
        accept: "För att beställa måste du godkänna våra leveransvilkor",
      };
    }

    this.setState({ errors });

    //display errors
    if (errors && Object.keys(errors).length === 0) {
      return true;
    }
  };

  handleSubmit = async (event, createOrder) => {
    event.preventDefault();

    let valid = this.handleValidation();

    if (valid) {
      this.setState({ loading: true });

      const {
        cart: { id: cartId },
        name,
        email,
        mobile,
        deliveryDate,
        comment,
      } = this.state;

      let orderInput = {
        cartId,
        name,
        email,
        mobile,
        deliveryDate,
        comment,
      };

      try {
        const orderResult = await createOrder({
          variables: { order: orderInput },
        });

        if (orderResult && orderResult.data) {
          const orderId = orderResult.data.createOrder.orderId;

          if (orderId) {
            this.setState({ loading: false });
            this.props.handleRemoveCart(null);
            this.props.history.push("/tack");
          }
        }
      } catch (err) {
        this.setState({ loading: false });
      }
    }
  };

  componentDidMount = () => {
    if (this.props.cart && this.props.cart !== this.state.cart) {
      this.setState({ cart: this.props.cart });
    }
  };

  componentDidUpdate = () => {
    if (this.props.cart !== this.state.cart) {
      this.setState({ cart: this.props.cart });
    }
  };

  render() {
    const {
      name,
      email,
      mobile,
      comment,
      showComment,
      accept,
      minDate,
      deliveryDate,
      loading,
      errors,
    } = this.state;

    return (
      <Container>
        <Row>
          <h1>Välj leveransdag</h1>
          <div>
            Vi behöver din beställning 2 dagar innan leveransdag. <br />
            Upphämtning sker mellan 07-19.
            <br />
            <br />
          </div>
          <Col>
            <DatePicker
              inline
              minDate={minDate}
              selected={deliveryDate}
              onChange={this.handleChangeDeliveryDate}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              minTime={setHours(setMinutes(new Date(), 0), 7)}
              maxTime={setHours(setMinutes(new Date(), 0), 19)}
              dateFormat="yyyy-MM-d, hh:mm"
              timeCaption="Tid"
              calendarClassName="cart-delivery-date"
              locale="sv"
            />
            {errors["deliveryDate"] && (
              <InputError>{errors["deliveryDate"]}</InputError>
            )}
          </Col>
        </Row>
        <Spacer />

        <Row>
          <FormWrapper>
            <h1 onClick={() => this.handleIncrementShowComment()}>
              Kontakt uppgifter
            </h1>
            <form>
              <FormGroup>
                <Label htmlFor="name">Namn</Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Fyll i ditt namn"
                  onChange={(e) => this.handleInputChange(e)}
                  value={name}
                  error={errors["name"]}
                />
                {errors["name"] && <InputError>{errors["name"]}</InputError>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">E-post</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Fyll i din e-post"
                  onChange={(e) => this.handleInputChange(e)}
                  value={email}
                  error={errors["email"]}
                />
                {errors["email"] && <InputError>{errors["email"]}</InputError>}
              </FormGroup>
              <FormGroup>
                <Label htmlFor="mobile">Mobil</Label>
                <Input
                  type="text"
                  id="mobile"
                  name="mobile"
                  placeholder="Fyll i ditt mobilnummer"
                  onChange={(e) => this.handleInputChange(e)}
                  value={mobile}
                  error={errors["mobile"]}
                />
                {errors["mobile"] && (
                  <InputError>{errors["mobile"]}</InputError>
                )}
              </FormGroup>
              {showComment && (
                <FormGroup>
                  <Label htmlFor="comment">Kommentar</Label>
                  <TextArea
                    type="text"
                    id="comment"
                    name="comment"
                    placeholder="Fyll i kommentar"
                    onChange={(e) => this.handleInputChange(e)}
                    value={comment}
                    error={errors["comment"]}
                    rows={4}
                  />
                  {errors["comment"] && (
                    <InputError>{errors["comment"]}</InputError>
                  )}
                </FormGroup>
              )}
              <Checkbox
                type="checkbox"
                id="accept"
                name="accept"
                onChange={(e) => this.handleCheckboxChange(e)}
                checked={accept}
                error={errors["accept"]}
                color="success-o"
                shape="curve"
                bigger
              >
                Jag accepterar
              </Checkbox>
              <Link to="/leveransvilkor">leveransvilkoren</Link>
              {errors["accept"] && <InputError>{errors["accept"]}</InputError>}

              <Mutation mutation={CREATE_ORDER_MUTATION}>
                {(createOrder) => (
                  <ButtonFilled
                    label="Skicka beställning"
                    loading={loading}
                    onClick={(e) => this.handleSubmit(e, createOrder)}
                  />
                )}
              </Mutation>
            </form>
          </FormWrapper>
        </Row>
      </Container>
    );
  }
}

export default withRouter(CartForm);
