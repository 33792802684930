import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo-hooks";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Radio } from "pretty-checkbox-react";
import _ from "lodash/array";
import "./Dish.css";

import { RestaurantContext } from "../../context";

import { ButtonFilled, Quantity, Heart } from "../../components";

import { formatPrice } from "../../utils";

import { ADD_TO_CART_MUTATION } from "../../graphql/mutation";

const URL = process.env.REACT_APP_STORAGE_URL;
const URL_VERSION = process.env.REACT_APP_STORAGE_URL_VERSION;
const IMAGE_QUAL_PHONE = "w_560,f_auto,q_auto,dpr_2.0";
const IMAGE_QUAL_TABLET = "w_870,f_auto,q_auto,dpr_2.0";
const IMAGE_QUAL_DESKTOP = "w_500,f_auto,q_auto,dpr_2.0";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Category = styled.div`
  font-family: "Timber";
  font-size: 46px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 1rem;
  padding-left: 15px;
  padding-bottom: 0.5rem;

  @media (max-width: 900px) {
    font-size: 40px;

    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 32px;

    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
  }
`;

const DishContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin-bottom: 2rem;
  background-color: #fafafa;
  border-radius: 8px;

  @media (min-width: 901px) {
    flex-direction: row;
    padding: 1rem;
  }
`;

// prettier-ignore
const DishImage = styled.div`
	background-color: #f1f1f1;
	background-size: cover;
	background-position: center center;
	height: 240px;
	min-width: 420px;
	margin: 0;

  @media (max-width: 600px) {
		min-width: 240px;

    background-image: url('${(prop) => `${URL}/${IMAGE_QUAL_PHONE}/${URL_VERSION}/products/${prop.src}.jpg`}');
  }
 
	@media (min-width: 600px) and (max-width: 900px) {
    background-image: url('${(prop) => `${URL}/${IMAGE_QUAL_TABLET}/${URL_VERSION}/products/${prop.src}.jpg`}');
  }

  @media (min-width: 900px) {
    background-image: url('${(prop) => `${URL}/${IMAGE_QUAL_DESKTOP}/${URL_VERSION}/products/${prop.src}.jpg`}');
  }
`;

const DishContent = styled.div`
  display: flex;
  flex: 2;
`;

const DishDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 1rem;

  @media (max-width: 900px) {
    margin: 1rem;
  }
`;

const DishHeadline = styled.div`
  display: flex;
`;

const DishName = styled.div`
  flex: 1;
  font-family: "Timber";
  font-size: 30px;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 28px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`;

const DishPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: row;
  }
`;

const DishPrice = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  text-align: right;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const DishDescription = styled.div`
  flex: 1;
  white-space: pre-line;
`;

const DishAddToCart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Tag = styled.span`
  background-color: #aee595;
  border: 1px solid #93c17d;
  border-radius: 4px;
  padding: 0px 4px;
  margin-right: 4px;
  text-transform: uppercase;
  font-size: 10px;
`;
const OutOfStock = styled.span`
  background-color: #e96565;
  border: 1px solid #d15a5a;
  color: white;
  border-radius: 4px;
  padding: 0px 4px;
  margin-right: 4px;
  text-transform: uppercase;
  font-size: 10px;
`;

const Dish = ({ dish, showCategory }) => {
  const {
    favorites,
    handleFavoriteAdd,
    handleFavoriteRemove,
    cartId,
    setCartId,
    restaurantData,
  } = useContext(RestaurantContext);

  const [price, setPrice] = useState(dish.priceVat);
  const [option, setOption] = useState(0);
  const [quantity, setQuantity] = useState(dish.quantityMin || 1);
  const [loading, setLoading] = useState(false);

  const [addToCartMutation] = useMutation(ADD_TO_CART_MUTATION);

  const handleOptionChange = (index, price) => {
    setOption(index);
    setPrice(price);
  };

  const tagReplacer = (tag) => {
    return <Tag>{tag}</Tag>;
  };

  const handleAddToCart = async ({ id, name, options }) => {
    if (id && quantity > 0) {
      setLoading(true);

      let variables = {
        itemId: id,
        quantity,
      };

      if (cartId) {
        variables.id = cartId;
      }

      if (options && options.length > 0) {
        variables.optionId = options[option].id;
      }

      try {
        const result = await addToCartMutation({
          variables,
          refetchQueries: ["GET_CART"],
        });

        if (result && result.data && result.data.addToCart) {
          toast.success(`${name} (${quantity}st) lagd i varukorg`);

          if (
            (!cartId && result.data.addToCart.id) ||
            cartId !== result.data.addToCart.id
          ) {
            setCartId(result.data.addToCart.id);
          }

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.error(`Något gick fel: ${err.message}`);
      }
    }
  };

  return (
    <Wrapper>
      {showCategory ? <Category>{dish.category}</Category> : null}
      <DishContainer key={dish.id}>
        <DishImage src={dish.image}>
          {_.findIndex(favorites, { id: dish.id }) === -1 ? (
            <Heart
              size="30"
              onClick={() => handleFavoriteAdd(dish)}
              style={{
                marginTop: "15px",
                marginRight: "15px",
              }}
            />
          ) : (
            <Heart
              size="30"
              onClick={() => handleFavoriteRemove(dish)}
              selected={true}
              style={{
                marginTop: "15px",
                marginRight: "15px",
              }}
            />
          )}
        </DishImage>
        <DishContent>
          <DishDetails>
            <DishHeadline>
              <DishName>{dish.name}</DishName>
              <DishPriceWrapper>
                <DishPrice>
                  {dish.pricePrefix && `${dish.pricePrefix} `}
                  {price > 0 ? formatPrice(price) : null}
                  {dish.priceType && ` / ${dish.priceType}`}
                  <br />
                  {dish.outOfStock && <OutOfStock>Tillfälligt slut</OutOfStock>}
                </DishPrice>
              </DishPriceWrapper>
            </DishHeadline>
            <DishDescription>
              {dish.description && <div>{dish.description}</div>}

              {dish.tag && dish.tag.length > 0 ? (
                <div style={{ marginTop: "0.2rem" }}>
                  {dish.tag.map((tag) => (
                    <span key={tag}>{tagReplacer(tag)}</span>
                  ))}
                </div>
              ) : null}

              {dish.ingredients && dish.ingredients.length > 0 ? (
                <div style={{ marginTop: "1rem" }}>
                  <strong>Innehåll:</strong>
                  <div>{dish.ingredients.join(", ")}</div>
                </div>
              ) : null}

              {dish.quantityMin && (
                <div style={{ marginTop: "1rem" }}>
                  <div>
                    <strong>Minimum:</strong>
                    {` ${dish.quantityMin} ${dish.priceType}`}
                  </div>
                </div>
              )}

              {dish.options && dish.options.length > 0 ? (
                <div style={{ marginTop: "1rem" }}>
                  <strong>
                    {dish.optionsLabel ? `${dish.optionsLabel}:` : "Val:"}
                  </strong>
                  {dish.optionsShowStandard ? (
                    <div>
                      <Radio
                        color="danger-o"
                        shape="curve"
                        type="radio"
                        value="standard|0"
                        name={`option-${dish.id}`}
                        id={dish.id}
                        onChange={() => handleOptionChange(null, dish.priceVat)}
                        checked={!option ? true : false}
                      />
                      <label htmlFor={dish.id}>Standard</label>
                    </div>
                  ) : null}

                  {dish.options.map((item, index) => {
                    if (item.active) {
                      return (
                        <div key={item.id}>
                          {!dish.optionsShowStandard && index === 0 ? (
                            <Radio
                              color="danger-o"
                              shape="curve"
                              type="radio"
                              value={item.name}
                              name={`option-${dish.id}`}
                              id={item.id}
                              onChange={() =>
                                handleOptionChange(index, item.priceVat)
                              }
                              checked={!option ? true : false}
                            />
                          ) : (
                            <Radio
                              color="danger-o"
                              shape="curve"
                              type="radio"
                              value={item.name}
                              name={`option-${dish.id}`}
                              id={item.id}
                              onChange={() =>
                                handleOptionChange(index, item.priceVat)
                              }
                            />
                          )}
                          <label htmlFor={item.id}>
                            {item.name}{" "}
                            {item.tag &&
                              item.tag.length > 0 &&
                              item.tag.map((tag) => (
                                <Tag key={`${tag}`}>{tag}</Tag>
                              ))}
                          </label>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              ) : null}
            </DishDescription>
            {restaurantData &&
            restaurantData.feature &&
            restaurantData.feature.catering ? (
              <DishAddToCart>
                {dish.price > 0 && !dish.outOfStock ? (
                  <Quantity
                    quantity={quantity}
                    quantityMin={dish.quantityMin || 1}
                    quantityMax={dish.quantityMax || 99}
                    setQuantity={setQuantity}
                  />
                ) : null}
                {dish.price > 0 && !dish.outOfStock ? (
                  <ButtonFilled
                    onClick={() => handleAddToCart(dish)}
                    label="Beställ"
                    path="/varukorg"
                    loading={loading}
                    disabled={loading}
                    dark
                  />
                ) : null}
              </DishAddToCart>
            ) : (
              <div />
            )}
          </DishDetails>
        </DishContent>
      </DishContainer>
    </Wrapper>
  );
};

export default Dish;
