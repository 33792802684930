import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";
import { fetchContactData } from "../../utils";

import {
  Button,
  ButtonPrimary,
  ButtonGroup,
  MobilePageHeader,
} from "../../components";
import { BackgroundImage, Overlay, Header, Description } from "../../styles";
import { Mobile, Tablet, Desktop } from "../../theme";

const Container = styled.div`
  flex: 1;
  background-color: #eee;
`;

const Spacer = styled.div`
  padding-top: ${(prop) => (prop.space ? prop.space : "1rem")};
`;

const Thanks = () => {
  const { restaurant } = useContext(RestaurantContext);
  const ContactData = fetchContactData(restaurant);

  const cateringContactMobile = ContactData.cateringContact.map(
    (item) =>
      `
      ${item.department} ${item.phone}`
  );

  const cateringContact = ContactData.cateringContact.map((item) => (
    <p key={item.department}>
      {item.department} {item.phone}
    </p>
  ));

  const text = `Din beställning är nu skickad. 
  Strax kommer en bekräftelse på din
  beställning via SMS. 
  
  Vi hoppas du kommer bli nöjd, vid frågor kontakta oss via telefon nedan. 
  
  Kontaktuppgifter ${cateringContactMobile}`;

  return (
    <Container>
      <Mobile>
        <MobilePageHeader header="Tack" text={text} noBack={true} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
            paddingBottom: "2rem",
          }}
        >
          <ButtonPrimary path="/" width="240px" label="Till startsidan" />
        </div>
      </Mobile>
      <Tablet>
        <BackgroundImage src="chark">
          <Overlay>
            <Header>Tack!</Header>
            <Description center>
              Din beställning är nu skickad. Strax kommer en bekräftelse på din
              beställning via SMS. Vi hoppas du kommer bli nöjd, vid frågor
              kontakta oss via telefon nedan.
            </Description>
            <ButtonGroup>
              <Button
                key="start"
                label="Till startsidan"
                path="/"
                width="240px"
              />
            </ButtonGroup>
            <Spacer space="2rem" />
            <Description center>
              <strong>Kontaktuppgifter</strong>
              {cateringContact}
            </Description>
          </Overlay>
        </BackgroundImage>
      </Tablet>
      <Desktop>
        <BackgroundImage src="chark">
          <Overlay>
            <Header>Tack!</Header>
            <Description center>
              Din beställning är nu skickad. Strax kommer en bekräftelse på din
              beställning via SMS. Vi hoppas du kommer bli nöjd, vid frågor
              kontakta oss via telefon nedan.
            </Description>
            <ButtonGroup>
              <Button
                key="start"
                label="Till startsidan"
                path="/"
                width="310px"
              />
            </ButtonGroup>
            <Spacer space="3rem" />
            <Description center>
              <strong>Kontaktuppgifter</strong>
              {cateringContact}
            </Description>
          </Overlay>
        </BackgroundImage>
      </Desktop>
    </Container>
  );
};

export default Thanks;
