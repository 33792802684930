import { Services } from "../../db";

const fetchServices = restaurant => {
  if (restaurant) {
    const { services } = Services.find(item => item.restaurant === restaurant);

    if (services) {
      return services;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export default fetchServices;
