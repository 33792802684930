import React from "react";
import styled from "styled-components";

const ImageGroupContainer = styled.div`
  display: flex;
  margin-top: 4rem;
`;

const ImageGroup = ({ children }) => (
  <ImageGroupContainer>{children}</ImageGroupContainer>
);

export default ImageGroup;
