import React from "react";
import styled from "styled-components";

import { FaGenderless } from 'react-icons/fa';

const Wrapper = styled.div`
  color: white;
  font-size: 10px;
  margin-top: 6px;
  margin-right: 4px;
`;

const Diamond = () => (
  <Wrapper><FaGenderless /></Wrapper>
);

export default Diamond;