import React, { useContext } from "react";
import styled from "styled-components";
import Select from "react-styled-select";

import "./RestaurantSelector.css";

import { RestaurantContext } from "../../context";
import { Restaurants } from "../../db";

import { MapMarker } from "../../styles";

const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
`;

const MapContainer = styled.div`
  svg {
    path {
      fill: rgba(0, 0, 0, 1);
    }
  }
  font-size: 18px;
  margin-top: -48px;
  padding-top: 8px;
  text-align: center;
  background-color: white;
`;

const RestaurantSelector = ({ mobile }) => {
  const { restaurant, setRestaurant, toggleRestaurant } = useContext(
    RestaurantContext
  );

  if (mobile) {
    return (
      <MapContainer onClick={() => toggleRestaurant()}>
        {Restaurants &&
          Restaurants.map((item) => {
            if (item.value === restaurant) {
              return (
                <div key={item.value}>
                  <MapMarker height="12" width="12" /> {item.label}
                </div>
              );
            } else {
              return null;
            }
          })}
      </MapContainer>
    );
  } else {
    return (
      <SelectorContainer>
        <Select
          options={Restaurants}
          value={restaurant}
          className={restaurant ? "select-dark" : "select-light"}
          onChange={(value) => setRestaurant(value)}
          placeholder="Välj restaurang"
          searchable={false}
          clearable={false}
          style={{
            width: "200px",
          }}
        />
      </SelectorContainer>
    );
  }
};

export default RestaurantSelector;
