import styled from "styled-components";

const SubHeader = styled.div`
  font-size: 17px;
  color: ${prop => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: center;
  width: 80%;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
  }

  @media (min-width: 901px) {
    font-size: 25px;
    width: 65%;
  }
`;

export default SubHeader;
