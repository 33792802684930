import { Contacts } from "../../db";

const fetchContactData = restaurant => {
  if (restaurant) {
    const contact = Contacts.find(item => item.restaurant === restaurant);

    if (contact) {
      return contact;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export default fetchContactData;
