import styled from "styled-components";

/*const SubHeader2 = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: ${prop => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: center;
  width: 80%;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
  }

  @media (min-width: 901px) {
    font-size: 32px;
    width: 65%;
  }
`;*/

const SubHeader2 = styled.div`
  font-family: "Timber";
  font-size: 30px;
  font-weight: 500;
  padding-top: 1.5rem;
  color: white;
`;

export default SubHeader2;
