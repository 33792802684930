import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";

const ButtonPrimaryContainer = styled(Link)`
  font-size: 24px;
  text-align: center;
  color: #e96565;
  /*background-color: #e96565;*/
  border: 2px solid #e96565; /*rgba(0, 0, 0, 0.2);*/
  padding: 0.5rem 1rem 0rem 1rem;
  width: ${props => (props.width ? props.width : "inherit")};
  height: 38px;
  margin: 0.5rem;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const ButtonPrimary = ({ path, label, width }) => (
  <ButtonPrimaryContainer to={path} width={width}>
    {label}
  </ButtonPrimaryContainer>
);

export default withRouter(ButtonPrimary);
