import gql from "graphql-tag";

const LUNCH_QUERY = gql`
  query Lunch($year: Int!, $week: Int!) {
    lunch(year: $year, week: $week) {
      dishes {
        id
        dayId
        day
        name
      }
    }
  }
`;

const FOOD_QUERY = gql`
  query FOOD_QUERY($area: String, $tag: [String]) {
    foodByFilter(filter: { area: $area, tag: $tag }) {
      id
      name
      description
      ingredients
      image
      pricePrefix
      price
      priceVat
      priceType
      quantityMin
      quantityMax
      vat
      area
      category
      tag
      optionsLabel
      optionsShowStandard
      options {
        id
        name
        price
        priceVat
        tag
        active
      }
      outOfStock
    }
  }
`;

const GET_CART = gql`
  query GET_CART($id: ID) {
    cart(id: $id) {
      id
      items {
        id
        name
        image
        price
        priceVat
        vat
        quantity
        tag
        option
      }
      total
      totalVat
    }
  }
`;

export { LUNCH_QUERY, FOOD_QUERY, GET_CART };
