import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { RestaurantContext } from "../../context";
import { fetchContactData } from "../../utils";

import { Desktop } from "../../theme";
import { Version } from "../../styles";
import { version } from "../../../package.json";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 380px;
  background-color: rgba(0, 0, 0, 0.8);
`;

const Address = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
`;

const Logo = styled.img`
  height: 92px;
  width: 210px;
  margin-bottom: 36px;
  background-color: rgba(0, 0, 0, 0.2);
`;

const BottomText = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 17px;
`;

const Social = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialIcon = styled.a`
  padding: 10px;
  svg {
    path {
      fill: rgba(255, 255, 255, 0.7);
    }
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkStyled = styled(Link)`
  margin-right: 2rem;
  margin-left: 2rem;
  color: rgba(255, 255, 255, 0.5);
`;

const Footer = () => {
  const { restaurant } = useContext(RestaurantContext);
  const ContactData = fetchContactData(restaurant);

  return (
    <footer>
      <Desktop>
        <Container>
          <Address>
            <Logo src="./images/logo_black.png" alt="Ediths Kök" />
            Ediths Kök | {ContactData.adress} | {ContactData.zip}{" "}
            {ContactData.city} | Tel: {ContactData.phone}
          </Address>
          <Social>
            <SocialIcon
              href="https://www.instagram.com/restaurangediths/"
              target="_blank"
              rel="noopener"
              aria-label="Instagram"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z" />
              </svg>
            </SocialIcon>
            <SocialIcon
              href="https://www.facebook.com/edithskok/"
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z" />
              </svg>
            </SocialIcon>
            <SocialIcon
              href={`mailto:${ContactData.email}`}
              target="_blank"
              rel="noopener"
              aria-label={ContactData.email}
            >
              <svg width="30" height="30" viewBox="0 0 75.294 75.294">
                <path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9 c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089 H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065 c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016 c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102 c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069 c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z" />
              </svg>
            </SocialIcon>
          </Social>
          <BottomText>Ediths Kök är en del i Nolgården koncernen.</BottomText>
          <Links>
            <LinkStyled to="/integritetspolicy">Integritetspolicy</LinkStyled>
            <LinkStyled to="/leveransvilkor">Leveransvilkor</LinkStyled>
          </Links>
          <Version>v{version}</Version>
        </Container>
      </Desktop>
    </footer>
  );
};

export default Footer;
