import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #e96565;
  padding: 12px;
`;

const Text = styled.div`
  color: white;
`;

const Button = styled.button`
  font-size: 24px;
  text-align: center;
  color: white;
  border: 1px solid white;
  padding: 0rem 1rem 0rem 1rem;
  height: 38px;
  margin: 0.5rem;
  margin-left: 1rem;
  background: transparent;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${props =>
      props.dark ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0.2)"};
  }
`;

class UpdateChecker extends Component {
  state = {
    version: null
  };

  componentDidMount = async () => {
    const version = await localStorage.getItem("edithVersion");
    this.setState({ version });
  };

  render() {
    const { version } = this.state;
    const GET_VERSION = gql`
      query GET_VERSION {
        version {
          name
        }
      }
    `;

    const handleUpdateVersion = async version => {
      if (version) {
        await localStorage.setItem("edithVersion", version);
        window.location.reload(true);
      }
    };

    return (
      <Query query={GET_VERSION}>
        {({ data }) => {
          if (data && data.version) {
            const serverVersion = data.version.name;

            if (version === null) {
              localStorage.setItem("edithVersion", serverVersion);
              return null;
            } else {
              if (serverVersion !== version) {
                return (
                  <Container>
                    <Text>
                      <strong>Ny version tillgänglig!</strong> ({"v"}
                      {serverVersion})
                    </Text>
                    <Button onClick={() => handleUpdateVersion(serverVersion)}>
                      Uppdatera
                    </Button>
                  </Container>
                );
              }
            }
          }

          return null;
        }}
      </Query>
    );
  }
}

export default UpdateChecker;
