import React from 'react';
import styled from 'styled-components';

const SmallImageContainer = styled.img`
	flex: 1;
	margin: 0.5rem;
	width: 175px;
	height: 164px;
	border: 2px solid white;
`;

const SmallImage = ({ src, alt }) => <SmallImageContainer src={`/images/thumbnails/${src}.jpg`} alt={alt} />;

export default SmallImage;
