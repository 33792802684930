import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

import { RestaurantContext } from "../../context";

import { Footer, RestaurantSelectorLading } from "../../components";

import {
  Breakfast,
  Cafe,
  Catering,
  Christmas,
  History,
  Home,
  Lunch,
  Restaurant,
  FourOFour,
  DeliveryConditions,
  PrivacyPolicy,
  FoodBoxes
} from "../../pages";

const Routes = () => {
  const { restaurant } = useContext(RestaurantContext);

  if (restaurant) {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/frukost" component={Breakfast} />
          <Route exact path="/cafe" component={Cafe} />
          <Route exact path="/catering" component={Catering} />
          <Route exact path="/catering-mat" component={Catering} />
          <Route exact path="/catering-dessert" component={Catering} />
          <Route exact path="/jul" component={Christmas} />
          <Route exact path="/historia" component={History} />
          <Route exact path="/lunch" component={Lunch} />
          <Route exact path="/restaurang" component={() => <Restaurant />} />
          <Route exact path="/leveransvilkor" component={DeliveryConditions} />
          <Route exact path="/integritetspolicy" component={PrivacyPolicy} />
          <Route exact path="/matlador" component={FoodBoxes} />
          <Route component={FourOFour} />
        </Switch>
        <Footer />
      </div>
    );
  } else {
    return <RestaurantSelectorLading />;
  }
};

export default Routes;
