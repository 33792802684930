import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";
import { fetchContactData, } from "../../utils";

import { Link, } from "../../components";
import { BackgroundImage, Overlay, Header, Description } from "../../styles";
import { Mobile, Tablet, Desktop } from "../../theme";

const Container = styled.div`
  flex: 1;
  background-color: #eee;
`;

const FoodBoxes = () => {
  const { restaurant } = useContext(RestaurantContext);
  const ContactData = fetchContactData(restaurant);
  const { foodboxesContacts } = ContactData;

  return (
    <Container>
      <Mobile>
        <BackgroundImage src="matlador">
          <Overlay>
            <Header>Ediths Matlådor</Header>

            <Description>
              Idag finns Ediths Färdigmat på många livsmedelsbutiker i Sverige. Är du intresserad av att bli återförsäljare? Kontakta oss!
            </Description>

            {foodboxesContacts && foodboxesContacts.map(({ name, phone }) => (<Link path={`tel:${phone}`} label={`${name}, ${phone}`} width="100" />))}
          </Overlay>
        </BackgroundImage>
      </Mobile>
      <Tablet>
        <BackgroundImage src="matlador" height={800}>
          <Overlay>
            <Header>Ediths Matlådor</Header>

            <Description>
              Idag finns Ediths Färdigmat på många livsmedelsbutiker i Sverige. Är du intresserad av att bli återförsäljare? Kontakta oss!
            </Description>

            {foodboxesContacts && foodboxesContacts.map(({ name, phone }) => (<Link path={`tel:${phone}`} label={`${name}, ${phone}`} width="100" />))}
          </Overlay>
        </BackgroundImage>
      </Tablet>
      <Desktop>
        <BackgroundImage src="matlador">
          <Overlay>
            <Header>Ediths Matlådor</Header>

            <Description>
              Idag finns Ediths Färdigmat på många livsmedelsbutiker i Sverige. Är du intresserad av att bli återförsäljare? Kontakta oss!
            </Description>

            {foodboxesContacts && foodboxesContacts.map(({ name, phone }) => (<Link path={`tel:${phone}`} label={`${name}, ${phone}`} width="100" />))}
          </Overlay>
        </BackgroundImage>
      </Desktop>
    </Container>
  );
};

export default FoodBoxes;
