import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.a`
  font-size: 24px;
  text-align: center;
  color: ${(props) => (props.dark ? "black" : "white")};
  border: 1px solid ${(props) => (props.dark ? "black" : "white")};
  padding: 0.5rem 1rem 0rem 1rem;
  width: ${(props) => (props.width ? props.width : "inherit")};
  height: 38px;
  margin: 0.5rem;
  background: transparent;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.dark ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0.2)"};
  }
`;

const ButtonLink = ({ path, label, width, dark }) => (
  <ButtonContainer
    href={path}
    width={width}
    dark={dark}
    target="_blank"
    rel="noopener"
  >
    {label}
  </ButtonContainer>
);

export default ButtonLink;
