import React from "react";
import styled from "styled-components";

import {
  OrnamentMobileTop,
  OrnamentMobileBottom,
  OrnamentDesktopTop,
  OrnamentDesktopBottom,
  Header
} from "../../styles";
import { Mobile, Tablet, Desktop } from "../../theme";

const Container = styled.div`
  flex: 1;
`;

const BackgroundImage = styled.div`
  background-image: url("images/edith_background.jpg");
  background-size: cover;
  background-position: center center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media (min-width: 901px) {
    background-image: url("images/services/edith-lg_1x.jpg");

    background-image: -webkit-image-set(
      url("images/services/edith-lg_1x.webp") 1x,
      url("images/services/edith-lg_2x.webp") 2x
    );
    background-image: image-set(
      url("images/services/edith-lg_1x.jpg") 1x,
      url("images/services/edith-lg_2x.jpg") 2x
    );
  }
`;

const Overlay = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);

  @media (min-width: 901px) {
    flex: unset;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin: 0;
    width: 800px;
  }
`;

const SubHeader = styled.div`
  font-size: 17px;
  line-height: 1.4;
  color: white;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 22px;
  }

  @media (min-width: 901px) {
    font-size: 22px;
  }
`;

const TextContainer = styled.div`
  max-width: 555px;
`;

const Text = styled.div`
  padding: 0.5rem;
  color: white;
  font-weight: 100;
  font-size: 17px;
  letter-spacing: 1px;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 22px;
  }

  @media (min-width: 901px) {
    font-size: 22px;
  }
`;

const History = () => (
  <Container>
    <Mobile>
      <BackgroundImage>
        <Overlay>
          <Header>Vår Historia</Header>
          <SubHeader>Hur allt blev som det blev</SubHeader>
          <OrnamentMobileTop />
          <Text>
            Hemlagat…Smaka på ordet en stund. Det finns inte mycket som känns
            mer ”på riktigt”. Hemlagat skickar tankarna till mat lagad med
            kärlek, tanke och omsorg. Så som det ska vara.
          </Text>
          <Text>
            Något som - om Farmor Edith fortfarande hade levt troligtvis hade
            fallit henne i smaken. För det var ju så det var. Då, i köket i
            huset, i Alingsås. Här fick de härliga råvarorna all den kärlek de
            förtjänade. Här lagade Edith mat och mycket annat med passion och
            kunskap som ärvts från generation till generation.
          </Text>
          <Text>
            Och det arvet för vi nu vidare in i Ediths Kök. För hur vi än vänder
            på det så finns det inget bättre än att sitta ner och njuta av något
            som är hemlagat. Varmt välkommen till Ediths. Här bjuds du på
            hemlagat. På riktigt.
          </Text>
          <OrnamentMobileBottom />
        </Overlay>
      </BackgroundImage>
    </Mobile>
    <Tablet>
      <BackgroundImage>
        <Overlay>
          <Header>Vår Historia</Header>
          <SubHeader>Hur allt blev som det blev</SubHeader>
          <OrnamentDesktopTop />
          <Text>
            Hemlagat…Smaka på ordet en stund. Det finns inte mycket som känns
            mer ”på riktigt”. Hemlagat skickar tankarna till mat lagad med
            kärlek, tanke och omsorg. Så som det ska vara.
          </Text>
          <Text>
            Något som - om Farmor Edith fortfarande hade levt troligtvis hade
            fallit henne i smaken. För det var ju så det var. Då, i köket i
            huset, i Alingsås. Här fick de härliga råvarorna all den kärlek de
            förtjänade. Här lagade Edith mat och mycket annat med passion och
            kunskap som ärvts från generation till generation.
          </Text>
          <Text>
            Och det arvet för vi nu vidare in i Ediths Kök. För hur vi än vänder
            på det så finns det inget bättre än att sitta ner och njuta av något
            som är hemlagat. Varmt välkommen till Ediths. Här bjuds du på
            hemlagat. På riktigt.
          </Text>
          <OrnamentDesktopBottom />
        </Overlay>
      </BackgroundImage>
    </Tablet>
    <Desktop>
      <BackgroundImage>
        <Overlay>
          <Header>Vår Historia</Header>
          <SubHeader>Hur allt blev som det blev</SubHeader>
          <OrnamentDesktopTop />
          <TextContainer>
            <Text>
              Hemlagat…Smaka på ordet en stund. Det finns inte mycket som känns
              mer ”på riktigt”. Hemlagat skickar tankarna till mat lagad med
              kärlek, tanke och omsorg. Så som det ska vara.
            </Text>
            <Text>
              Något som - om Farmor Edith fortfarande hade levt troligtvis hade
              fallit henne i smaken. För det var ju så det var. Då, i köket i
              huset, i Alingsås. Här fick de härliga råvarorna all den kärlek de
              förtjänade. Här lagade Edith mat och mycket annat med passion och
              kunskap som ärvts från generation till generation.
            </Text>
            <Text>
              Och det arvet för vi nu vidare in i Ediths Kök. För hur vi än
              vänder på det så finns det inget bättre än att sitta ner och njuta
              av något som är hemlagat. Varmt välkommen till Ediths. Här bjuds
              du på hemlagat. På riktigt.
            </Text>
          </TextContainer>
          <OrnamentDesktopBottom />
        </Overlay>
      </BackgroundImage>
    </Desktop>
  </Container>
);

export default History;
