import styled from "styled-components";

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media (min-width: 901px) {
    flex: unset;
    padding: 0;
    height: ${prop => (prop.small ? "700px" : "1100px")};
    width: 980px;
  }
`;

export default Overlay;
