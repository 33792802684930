import React, { useEffect, useState, Fragment } from "react";
import getISODay from "date-fns/getISODay";
import styled from "styled-components";

import { Mobile, Tablet, Desktop } from "../../theme";

import { BackgroundImage, Overlay, Header, SubHeader } from "../../styles";

import { Takeaway as takeaway } from "../../db";

const Container = styled.div`
  flex: 1;
  margin-bottom: 3px;
`;

const Button = styled.a`
  font-size: 16px;
  text-align: center;
  color: ${(props) => (props.dark ? "black" : "white")};
  border: 1px solid ${(props) => (props.dark ? "black" : "white")};
  width: ${(props) => (props.width ? props.width : "inherit")};
  height: 28px;
  padding: 0.5rem 1rem 0rem 1rem;
  margin: 0.5rem;
  background: transparent;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.dark ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0.2)"};
  }

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
    height: 34px;
    padding: 0.5rem 1rem 0rem 1rem;
    margin: 0.5rem;
  }

  @media (min-width: 901px) {
    font-size: 24px;
    height: 38px;
    padding: 0.5rem 1rem 0rem 1rem;
    margin: 0.5rem;
  }
`;

const Takeaway = () => {
  const [showTakeaway, setShowTakeaway] = useState(false);
  const [time, setTime] = useState();

  useEffect(() => {
    const today = new Date();
    const day = getISODay(today);
    const hour = today.getHours();

    const openHours = takeaway.openHours.filter(
      (openHour) => openHour.id === day
    )[0];
    setTime(openHours);

    if (
      openHours &&
      parseInt(openHours.open, 10) <= hour &&
      parseInt(openHours.close, 10) > hour
    ) {
      setShowTakeaway(true);
    }
  }, []);

  return (
    <Fragment>
      {time && (
        <Container>
          <Mobile>
            <BackgroundImage src="takeaway">
              <Overlay>
                <Header>Takeaway</Header>
                <SubHeader>
                  {time.name} {time.open}-{time.close}
                </SubHeader>
                {showTakeaway && time && (
                  <Button href={takeaway.url} target="_blank" rel="noopener">
                    Beställ
                  </Button>
                )}
              </Overlay>
            </BackgroundImage>
          </Mobile>

          <Tablet>
            <BackgroundImage src="takeaway">
              <Overlay>
                <Header>Takeaway</Header>
                <SubHeader>
                  {time.name} {time.open}-{time.close}
                </SubHeader>
                {showTakeaway && time && (
                  <Button href={takeaway.url} target="_blank" rel="noopener">
                    Beställ
                  </Button>
                )}
              </Overlay>
            </BackgroundImage>
          </Tablet>

          <Desktop>
            <BackgroundImage src="takeaway" small>
              <Overlay small>
                <Header>Takeaway</Header>
                <SubHeader>
                  {time.name} {time.open}-{time.close}
                </SubHeader>
                {showTakeaway && time && (
                  <Button
                    href={takeaway.url}
                    target="_blank"
                    rel="noopener"
                    width="120"
                  >
                    Beställ
                  </Button>
                )}
              </Overlay>
            </BackgroundImage>
          </Desktop>
        </Container>
      )}
    </Fragment>
  );
};

export default Takeaway;
