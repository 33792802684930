import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { CSSTransitionGroup } from "react-transition-group";

import { RestaurantContext } from "../../context";

import { version } from "../../../package.json";
import { Version } from "../../styles";

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  background: #33363c;
  transition: opacity 3s ease-in-out;
`;

const MenuItem = styled.div`
  flex: 1;
  color: #fff;
  padding: 25px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
`;

const CloseMenu = styled.div`
  position: absolute;
  color: #fff;
  top: 130px;
  right: 20px;
  font-size: 28px;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 601px) and (max-width: 900px) {
    top: 240px;
  }
`;

const MobileMenu = props => {
  const { history } = props;
  const { toggleMenu, menuOpen, restaurant } = useContext(RestaurantContext);

  const handleNavigate = (e, toggleMenu, history, path) => {
    e.preventDefault();
    toggleMenu();
    history.push(path);
  };

  const handleCloseMenu = toggleMenu => {
    toggleMenu();
  };

  if (menuOpen) {
    return (
      <CSSTransitionGroup
        transitionName="mobile-menu"
        transitionAppear={true}
        transitionAppearTimeout={300}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        <Menu>
          <CloseMenu onClick={() => handleCloseMenu(toggleMenu)}>
            &times;
          </CloseMenu>
          <MenuItem onClick={e => handleNavigate(e, toggleMenu, history, "/")}>
            Hem
          </MenuItem>
          <MenuItem
            onClick={e => handleNavigate(e, toggleMenu, history, "/lunch")}
          >
            Lunch
          </MenuItem>
          <MenuItem
            onClick={e => handleNavigate(e, toggleMenu, history, "/catering")}
          >
            Catering
          </MenuItem>
          <MenuItem
            onClick={e => handleNavigate(e, toggleMenu, history, "/restaurang")}
          >
            Restaurang
          </MenuItem>
          <MenuItem
            onClick={e => handleNavigate(e, toggleMenu, history, "/cafe")}
          >
            {restaurant && restaurant === "edi-ali-sod"
              ? "Café"
              : "Glass & Café"}
          </MenuItem>
          <MenuItem
            onClick={e => handleNavigate(e, toggleMenu, history, "/matlador")}
          >
            Ediths Matlådor
          </MenuItem>
          <MenuItem
            onClick={e => handleNavigate(e, toggleMenu, history, "/historia")}
          >
            Om oss
          </MenuItem>
          <Version>v{version}</Version>
        </Menu>
      </CSSTransitionGroup>
    );
  } else {
    return null;
  }
};

export default withRouter(MobileMenu);
