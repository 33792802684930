import React, { useContext, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useQuery } from "react-apollo-hooks";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";

import { RestaurantContext } from "../../context";
import { RestaurantSelector } from "../../components";

import { Mobile, Tablet, Desktop } from "../../theme";

import { GET_CART } from "../../graphql/query";

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;

  @media (min-width: 601px) {
    height: 220px;
  }
`;

const DesktopMenu = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabletMenu = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;

  @media (min-width: 901px) {
    justify-content: flex-end;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 901px) {
    justify-content: flex-start;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  height: ${(props) => (props.small ? "61px" : "85px")};
  width: ${(props) => (props.small ? "158px" : "220px")};
`;

const MenuLink = styled(Link)`
  font-size: 18px;
  padding: 25px;
  text-decoration: none;
  white-space: nowrap;
  color: #6f6a6a;
`;

const MenuItem = styled.div`
  padding: 25px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  color: #6f6a6a;
`;

const Hamburger = styled.img`
  padding: 25px;
  cursor: pointer;
`;

const Header = ({ history }) => {
  const { restaurant, toggleMenu, menuOpen, cartId } =
    useContext(RestaurantContext);
  const [count, setCount] = useState(0);

  const { data, error } = useQuery(GET_CART, {
    variables: { id: cartId },
  });

  if (error) toast.error(`Något gick fel: ${error.message}`);

  if (data && data.cart) {
    const { cart } = data;
    const newCount = cart.items.reduce((sum, item) => sum + item.quantity, 0);

    if (count !== newCount) {
      setCount(newCount);
    }
  } else {
    if (count !== 0) {
      setCount(0);
    }
  }

  const handleNavigate = (menuOpen, toggleMenu, history, path) => {
    if (menuOpen) toggleMenu();
    history.push(path);
  };

  return (
    <header>
      <nav>
        <Container>
          <ToastContainer
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            hideProgressBar={false}
            closeButton={false}
            closeOnClick={false}
            autoClose={2000}
          />
          <Mobile>
            {restaurant ? (
              <TabletMenu>
                <Left>
                  <Hamburger
                    src="./images/menu.png"
                    width="16"
                    height="16"
                    alt="menu"
                    onClick={toggleMenu}
                  />
                </Left>
                <Center>
                  <MenuItem
                    onClick={() =>
                      handleNavigate(menuOpen, toggleMenu, history, "/")
                    }
                  >
                    <Logo src="./images/logo_org.png" alt="Ediths Kök" small />
                  </MenuItem>
                  <RestaurantSelector mobile />
                </Center>
                <Right />
              </TabletMenu>
            ) : (
              <TabletMenu>
                <Center>
                  <MenuItem
                    onClick={() =>
                      handleNavigate(menuOpen, toggleMenu, history, "/")
                    }
                  >
                    <Logo src="./images/logo_org.png" alt="Ediths Kök" small />
                  </MenuItem>
                </Center>
              </TabletMenu>
            )}
          </Mobile>
          <Tablet>
            {restaurant ? (
              <TabletMenu>
                <Left>
                  <Hamburger
                    src="./images/menu.png"
                    width="24"
                    height="24"
                    alt="menu"
                    onClick={toggleMenu}
                  />
                </Left>
                <Center>
                  <MenuItem
                    onClick={() =>
                      handleNavigate(menuOpen, toggleMenu, history, "/")
                    }
                  >
                    <Logo src="./images/logo_org.png" alt="Ediths Kök" />
                  </MenuItem>
                  <RestaurantSelector />
                </Center>
                <Right />
              </TabletMenu>
            ) : (
              <TabletMenu>
                <Center>
                  <MenuItem
                    onClick={() =>
                      handleNavigate(menuOpen, toggleMenu, history, "/")
                    }
                  >
                    <Logo src="./images/logo_org.png" alt="Ediths Kök" />
                  </MenuItem>
                </Center>
              </TabletMenu>
            )}
          </Tablet>
          <Desktop>
            {restaurant ? (
              <DesktopMenu>
                <Left>
                  <MenuLink to="/">Hem</MenuLink>
                  <MenuLink to="/catering">Catering</MenuLink>
                  <MenuLink to="/restaurang">Restaurang</MenuLink>
                </Left>
                <Center>
                  <MenuLink to="/" aria-label="Ediths Kök">
                    <Logo src="./images/logo_org.png" alt="Ediths Kök" />
                  </MenuLink>
                  <RestaurantSelector />
                </Center>
                <Right>
                  {restaurant && restaurant === "edi-ali-sod" ? (
                    <MenuLink to="/cafe">Café</MenuLink>
                  ) : (
                    <MenuLink to="/cafe">Glass & Café</MenuLink>
                  )}
                  <MenuLink to="/matlador">Ediths Matlådor</MenuLink>
                  <MenuLink to="/historia">Om oss</MenuLink>
                </Right>
              </DesktopMenu>
            ) : (
              <DesktopMenu>
                <Center>
                  <MenuLink to="/" aria-label="Ediths Kök">
                    <Logo src="./images/logo_org.png" alt="Ediths Kök" />
                  </MenuLink>
                </Center>
              </DesktopMenu>
            )}
          </Desktop>
        </Container>
      </nav>
    </header>
  );
};

export default withRouter(Header);
