import React from "react";
import styled from "styled-components";
import {
  FaUser as User,
  FaPhone as Phone,
  FaShoppingBasket as Basket,
} from "react-icons/fa";

import { Header, SubHeader2 } from "../../styles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Overlay = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
  padding: 3rem;
  background-color: rgba(0, 0, 0, 0.4);

  margin: 0;
`;

const Item = styled.div`
  flex: 1;
  min-width: 300px;

  margin: 10px;
`;

const Description = styled.div`
  font-size: 17px;
  line-height: 1.4;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: ${(prop) => (prop.center ? "center" : "left")};

  @media (min-width: 601px) {
    font-size: 22px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1140px;
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const BackgroundImage = styled.div`
    background-image: url('images/services/${(prop) => prop.src}-lg_1x.jpg');

    background-image: -webkit-image-set(
        url('images/services/${(prop) => prop.src}-lg_1x.webp') 1x,
        url('images/services/${(prop) => prop.src}-lg_2x.webp') 2x
    );
    
    background-image: image-set(
        url('images/services/${(prop) => prop.src}-lg_1x.jpg') 1x,
        url('images/services/${(prop) => prop.src}-lg_2x.jpg') 2x
    );

	background-size: cover;
    
    display: flex;
    flex:1;
	justify-content: center;
	align-items: center;

	@media (min-width: 901px) {
        align-items: flex-start;
        background-size: cover;
        background-position: center center;
		width: 100%;
	}
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
`;

const Christmas = () => (
  <Container>
    <BackgroundImage src="julskinka">
      <Overlay>
        <Header>God Jul & Gott Nytt</Header>
        <Row>
          <Col>
            <Item>
              <Description>
                <SubHeader2>JULTALLRIK - 149kr</SubHeader2>
                Julskinka, köttbullar, prinskorv, Onsalakorv, rödbetssallad,
                varmrökt lax, kallrökt lax, ägg, skagenröra, handskalade räkor,
                brieost & två sorters sill
                <small> (Orginal/Vegetarisk)</small>
              </Description>
            </Item>
            <Item>
              <Description>
                <SubHeader2>JULLANDGÅNG - 99kr</SubHeader2>
                Julskinka, rödbetssallad, köttbullar, kallrökt lax, skagenröra,
                handskalade räkor, ägg & brieost
                <small> (Orginal/Vegetarisk)</small>
              </Description>
            </Item>
            <Item>
              <Description>
                <SubHeader2>JULMACKA - 69kr</SubHeader2>
                Bageriets vörtbröd, köttbullar, rödbetssallad, julskinka, stark
                senap & grönsaker
              </Description>
            </Item>
            <Item>
              <Description>
                <SubHeader2>JULKORG - från 249kr</SubHeader2>
                Vi plockar ihop godsaker som hör julen till, allt samlat i en
                fin korg inslaget i cellufan. <br />
                <br />
                Finns i tre olika storlekar.
                <br />
                <Basket size="16" />
                &nbsp;&nbsp;S 249:- / M 399:- / L 599:-
                <br />
                <br />
                <Link href="tel:+4632278449">
                  Beställ via Kallköket (från 25/11)
                  <br />
                  <Phone size="16" />
                  &nbsp;&nbsp;0322-78449
                </Link>
              </Description>
            </Item>
          </Col>
          <Col>
            <Item>
              <Description>
                <SubHeader2>LILLA JULBORDET - 169kr/per person</SubHeader2>
                Senapssill, inlagd löksill, gravad lax med hovmästarsås,
                julskinka, rökt korv, ägghalvor med rom, köttbullar,
                prinskorvar, jansson frestelse, rödbetssallad, smör, brieost &
                marmelad, vörtbröd & knäckekex
                <br />
                <br />
                <small>
                  <User size="16" /> Minimum beställning 4 personer
                </small>
              </Description>
            </Item>
            <Item>
              <Description>
                <SubHeader2>STORA JULBORDET - 199kr/per person</SubHeader2>
                Senapssill, inlagd löksill, skagensill, varmrökt lax med romsås,
                gravad lax med hovmästarsås, skaldjurspaté, skagenröra,
                ägghalvor med rom, julskinka, grönkålssallad, rökt korv,
                köttbullar, prinskorvar, tjocka revben, rödkål, janssons
                frestelse, rödbetssallad, smör, brieost & ädelost med marmelad,
                vörtbröd, ost & knäckebröd
                <br />
                <br />
                <small>
                  <User size="16" /> Minimum beställning 4 personer
                </small>
                <br />
                <br />
                Många av produkterna kan även beställas separat. Ring oss för
                mer information
                <br />
                <br />
                <Link href="tel:+4632278447">
                  Beställ via Varmköket (från 25/11)
                  <br />
                  <Phone size="16" />
                  &nbsp;&nbsp;0322-78447
                </Link>
              </Description>
            </Item>
          </Col>
        </Row>
        <br />
        <br />
        <Description>
          <small>
            OBS! Vi behöver din beställning 2 dagar innan önskad leveransdag
          </small>
        </Description>
      </Overlay>
    </BackgroundImage>
  </Container>
);

export default Christmas;
