import styled from 'styled-components';

const Header = styled.div`
	font-family: "Timber";
	font-size: 45px;
	color: ${(prop) => (prop.dark ? 'rgba(0, 0, 0, 0.6)' : '#fff')};
	text-transform: uppercase;
	text-align: center;
	width: 80%;
	line-height: 1;

	@media (min-width: 601px) and (max-width: 900px) {
		font-size: 65px;
	}

	@media (min-width: 901px) {
		font-size: 90px;
	}
`;

export default Header;
