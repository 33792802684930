import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";

import {
  BackgroundImage,
  Overlay,
  Header,
  SubHeader2,
  Description,
} from "../../styles";

const Container = styled.div`
  flex: 1;
  margin-bottom: 3px;
`;

const Landing = () => {
  const { restaurantData } = useContext(RestaurantContext);

  return restaurantData &&
    restaurantData.feature &&
    restaurantData.feature.breakfast ? (
    <Container>
      <section>
        <BackgroundImage src="frukost">
          <Overlay>
            <Header>Frukost</Header>
            <Description center>
              <SubHeader2>Ediths Frukostbuffé</SubHeader2>
              Serveras fredagar mellan 07-10
              <br />
              <br />
              Välkommna!
            </Description>
          </Overlay>
        </BackgroundImage>
      </section>
    </Container>
  ) : null;
};

export default Landing;
