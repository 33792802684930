import contact from "./contact";

const restVimpeln = contact[0];
const restLasarettet = contact[1];

const Services = [
  /* EDITHS - VIMPELN */
  {
    restaurant: "edi-ali-hem",
    services: [
      /* LUNCH */
      {
        id: 1,
        type: "mobile",
        data: {
          path: "/lunch",
          image: "lunch",
          header: "Veckans dagens",
          subheader: "Klicka här för att se veckans meny.",
          lunch: true,
        },
      },
      {
        id: 2,
        type: "tablet",
        data: {
          path: "/lunch",
          image: "lunch",
          header: "Veckans dagens",
          subheader: "Klicka här för att se veckans meny.",
          lunch: true,
        },
      },
      {
        id: 3,
        type: "desktop",
        data: {
          path: "/lunch",
          image: "lunch",
          header: "Veckans dagens",
          subheader: `Dagens lunch från ${restVimpeln.lunchPrice}:-`,
          lunch: true,
        },
      },
      /* FRUKOST - Hemlagad frukost alla vardagar i veckan. Klicka för att läsa mer!*/
      {
        id: 4,
        type: "mobile",
        data: {
          path: "/frukost",
          image: "frukost",
          header: "Frukost",
          subheader: "Hemlagad frukost varje fredag. Klicka för att läsa mer!",
        },
      },
      {
        id: 5,
        type: "tablet",
        data: {
          path: "/frukost",
          image: "frukost",
          header: "Frukost",
          subheader: "Hemlagad frukost varje fredag. Klicka för att läsa mer!",
        },
      },
      /* JUL */
      /*{
        id: 18,
        type: "mobile",
        data: {
          path: "/jul",
          image: "julskinka",
          header: "God Jul & Gott nytt!",
          subheader: "Beställ din julmat här! (från 25/11)",
        },
      },
      {
        id: 19,
        type: "tablet",
        data: {
          path: "/jul",
          image: "julskinka",
          header: "God Jul & Gott nytt!",
          subheader: "Beställ din julmat här! (Säljstart 25/11)",
        },
      },
      {
        id: 20,
        type: "desktop",
        data: {
          path: "/jul",
          image: "julskinka",
          header: "God Jul & Gott nytt!",
          description:
            "Från 25/11 kan du välj mellan lilla och stora julbordet eller varför inte smaka vår jultallrik? Annars kan du alltid överraska med vår julkorg!",
          buttons: [{ path: "/jul", label: "Beställ din julmat här" }],
        },
      },*/
      /* CATERING */
      {
        id: 6,
        type: "mobile",
        data: {
          path: "/catering",
          image: "chark",
          header: "Catering",
          subheader: "Beställ din catering här!",
        },
      },
      {
        id: 7,
        type: "tablet",
        data: {
          path: "/catering",
          image: "chark",
          header: "Catering",
          subheader: "Beställ din catering här!",
        },
      },
      {
        id: 8,
        type: "desktop",
        data: {
          path: "/catering",
          image: "chark",
          header: "Catering",
          subheader: "Beställ din catering här!",
          description:
            "Ediths cateringmenyer är varsamt komponerade med noga utvalda råvaror. Precis som det ska vara när man bjuder på hemlagat. Smaklig måltid.",
          buttons: [{ path: "/catering", label: "Se vårt utbud" }],
          smallImages: [
            { src: "smargastarta", alt: "Smörgåstårta" },
            { src: "lax", alt: "Lax" },
            { src: "spidermantarta", alt: "Spiderman tårta" },
            { src: "dessert", alt: "Dessert" },
          ],
        },
      },
      /* RESTAURANG */
      {
        id: 9,
        type: "mobile",
        data: {
          path: "/restaurang",
          image: "lax",
          header: "Restaurang",
          subheader: "Välkommen att smaka hemlagat på riktigt!",
        },
      },
      {
        id: 10,
        type: "tablet",
        data: {
          path: "/restaurang",
          image: "lax",
          header: "Restaurang",
          subheader: "Välkommen att smaka hemlagat på riktigt!",
        },
      },
      {
        id: 11,
        type: "desktop",
        data: {
          path: "/restaurang",
          image: "lax",
          header: "Restaurang",
          subheader: "Välkommen att smaka hemlagat på riktigt!",
          description:
            "Välkommen till Ediths Kök. En restaurang som med stolthet kan säga att allt vi erbjuder är hemlagad mat gjord med noga utvalda råvaror. Välkommen!",
          tagline: `${restVimpeln.openHours[0].day}: ${restVimpeln.openHours[0].time} | ${restVimpeln.openHours[1].day}: ${restVimpeln.openHours[1].time} | ${restVimpeln.openHours[2].day}: ${restVimpeln.openHours[2].time}`,
          buttons: [{ path: "/restaurang", label: "Läs mer" }],
          smallImages: [
            { src: "pizza", alt: "Pizza" },
            { src: "sushi", alt: "Sushi" },
            { src: "sallad", alt: "Sallad" },
            { src: "fralla", alt: "Fralla" },
          ],
        },
      },
      /* CAFE */
      {
        id: 12,
        type: "mobile",
        data: {
          path: "/cafe",
          image: "glass",
          header: "Glass & Café",
          subheader:
            "Egengjord Italiensk glass eller nybakat från konditoriet. Sugen?",
        },
      },
      {
        id: 13,
        type: "tablet",
        data: {
          path: "/cafe",
          image: "glass",
          header: "Glass & Café",
          subheader:
            "Egengjord Italiensk glass eller nybakat från konditoriet. Sugen?",
        },
      },
      {
        id: 14,
        type: "desktop",
        data: {
          path: "/cafe",
          image: "glass",
          header: "Glass & Café",
          subheader:
            "Egengjord Italiensk glass eller nybakat från konditoriet. Sugen?",
          description:
            "Sugen på fika? På Edith´s café hittar du allt från egengjord Italiensk glass till bakverk och kakor i alla dess former. Allt bakat från grunden i vårt bageri. Välkommen att sitta ner och njuta av en äkta fika.",
          buttons: [{ path: "/cafe", label: "Läs mer" }],
          smallImages: [
            { src: "glass", alt: "Glass" },
            { src: "tarta", alt: "Tårta" },
            { src: "tartbit", alt: "Tårtbit" },
            { src: "kaffe", alt: "Kaffe" },
          ],
        },
      },
      /* HISTORIA */
      {
        id: 15,
        type: "mobile",
        data: {
          path: "/historia",
          image: "edith",
          header: "Vår historia",
          subheader: "Läs om hur allt blev som det blev.",
        },
      },
      {
        id: 16,
        type: "tablet",
        data: {
          path: "/historia",
          image: "edith",
          header: "Vår historia",
          subheader: "Läs om hur allt blev som det blev.",
        },
      },
      {
        id: 17,
        type: "desktop",
        data: {
          path: "/historia",
          image: "edith",
          header: "Vår historia",
          subheader: "Läs om hur allt blev som det blev.",
          about: true,
        },
      },
    ],
  },
  /* EDITHS - LASARETTET */
  {
    restaurant: "edi-ali-sod",
    services: [
      {
        id: 1,
        type: "mobile",
        data: {
          path: "/lunch",
          image: "lunch",
          header: "Veckans dagens",
          subheader: "Klicka här för att se veckans meny.",
          lunch: true,
        },
      },
      {
        id: 2,
        type: "tablet",
        data: {
          path: "/lunch",
          image: "lunch",
          header: "Veckans dagens",
          subheader: "Klicka här för att se veckans meny.",
          lunch: true,
        },
      },
      {
        id: 3,
        type: "desktop",
        data: {
          image: "lunch",
          header: "Veckans dagens",
          subheader: `Dagens lunch från ${restLasarettet.lunchPrice}:-`,
          lunch: true,
        },
      },
      /* CATERING */
      {
        id: 4,
        type: "mobile",
        data: {
          path: "/catering",
          image: "chark",
          header: "Catering",
          subheader: "Beställ din catering här!",
        },
      },
      {
        id: 5,
        type: "tablet",
        data: {
          path: "/catering",
          image: "chark",
          header: "Catering",
          subheader: "Beställ din catering här!",
        },
      },
      {
        id: 6,
        type: "desktop",
        data: {
          path: "/catering",
          image: "chark",
          header: "Catering",
          subheader: "Beställ din catering här!",
          description:
            "Ediths cateringmenyer är varsamt komponerade med noga utvalda råvaror. Precis som det ska vara när man bjuder på hemlagat. Smaklig måltid.",
          buttons: [{ path: "/catering", label: "Se vårt utbud" }],
          smallImages: [
            { src: "smargastarta", alt: "Smörgåstårta" },
            { src: "lax", alt: "Lax" },
            { src: "spidermantarta", alt: "Spiderman tårta" },
            { src: "dessert", alt: "Dessert" },
          ],
        },
      },
      /* RESTAURANG */
      {
        id: 7,
        type: "mobile",
        data: {
          path: "/restaurang",
          image: "lax",
          header: "Restaurang",
          subheader: "Välkommen att smaka hemlagat på riktigt!",
        },
      },
      {
        id: 8,
        type: "tablet",
        data: {
          path: "/restaurang",
          image: "lax",
          header: "Restaurang",
          subheader: "Välkommen att smaka hemlagat på riktigt!",
        },
      },
      {
        id: 9,
        type: "desktop",
        data: {
          path: "/restaurang",
          image: "lax",
          header: "Restaurang",
          subheader: "Välkommen att smaka hemlagat på riktigt!",
          description:
            "Välkommen till Ediths Kök. En restaurang som med stolthet kan säga att allt vi erbjuder är hemlagad mat gjord med noga utvalda råvaror. Välkommen!",
          tagline: `Öppettider: ${restLasarettet.openHours[0].day}: ${restLasarettet.openHours[0].time}`,
          buttons: [{ path: "/restaurang", label: "Läs mer" }],
          smallImages: [
            { src: "kottbullar", alt: "Köttbullar" },
            { src: "sallad", alt: "Sallad" },
            { src: "fralla", alt: "Fralla" },
          ],
        },
      },
      /* CAFE */
      {
        id: 10,
        type: "mobile",
        data: {
          path: "/cafe",
          image: "fika",
          header: "Fika",
          subheader: "På Edith´s café hittar du alltid något nybakat. Sugen?",
        },
      },
      {
        id: 11,
        type: "tablet",
        data: {
          path: "/cafe",
          image: "fika",
          header: "Fika",
          subheader: "På Edith´s café hittar du alltid något nybakat. Sugen?",
        },
      },
      {
        id: 12,
        type: "desktop",
        data: {
          path: "/cafe",
          image: "fika",
          header: "Fika",
          subheader: "På Edith´s café hittar du alltid något nybakat. Sugen?",
          description:
            "Sugen på fika? På Edith´s café hittar du bakverk och kakor i alla dess former. Allt bakat från grunden i vårt bageri. Välkommen att sitta ner och njuta av en äkta fika.",
          buttons: [{ path: "/cafe", label: "Läs mer" }],
          smallImages: [
            { src: "bullar", alt: "Bullar" },
            { src: "princesstarta", alt: "Tårtbit" },
            { src: "kaffe", alt: "Kaffe" },
          ],
        },
      },
      /* HISTORIA */
      {
        id: 13,
        type: "mobile",
        data: {
          path: "/historia",
          image: "edith",
          header: "Vår historia",
          subheader: "Läs om hur allt blev som det blev.",
        },
      },
      {
        id: 14,
        type: "tablet",
        data: {
          path: "/historia",
          image: "edith",
          header: "Vår historia",
          subheader: "Läs om hur allt blev som det blev.",
        },
      },
      {
        id: 15,
        type: "desktop",
        data: {
          path: "/historia",
          image: "edith",
          header: "Vår historia",
          subheader: "Läs om hur allt blev som det blev.",
          about: true,
        },
      },
    ],
  },
];

export default Services;
