import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  ButtonGroup,
  ImageGroup,
  Lunch,
  SmallImage,
} from "../../components";
import { Mobile, Tablet, Desktop } from "../../theme";

import {
  BackgroundImage,
  OrnamentDesktopTop,
  OrnamentDesktopBottom,
  Overlay,
  Header,
  SubHeader,
  Description,
  Tagline,
} from "../../styles";

const Container = styled.div`
  flex: 1;
  margin-bottom: 3px;
`;

class Service extends Component {
  handleServiceClick = ({ path, history }) => {
    history.push(path);
  };

  render() {
    const { type, data, history, restaurantData } = this.props;
    const { lunchOpenHoursWeekdays, lunchOpenHoursWeekends } = restaurantData;

    return (
      <Container>
        {type === "mobile" && (
          <Mobile>
            <BackgroundImage src={data.image}>
              <Overlay
                onClick={() =>
                  this.handleServiceClick({ path: data.path, history })
                }
              >
                <Header>{data.header}</Header>
                {data.lunch && lunchOpenHoursWeekdays && (
                  <SubHeader>Vardagar {lunchOpenHoursWeekdays}</SubHeader>
                )}
                {data.lunch && lunchOpenHoursWeekends && (
                  <SubHeader>Helg {lunchOpenHoursWeekends}</SubHeader>
                )}
                <SubHeader>{data.subheader}</SubHeader>
              </Overlay>
            </BackgroundImage>
          </Mobile>
        )}

        {type === "tablet" && (
          <Tablet>
            <BackgroundImage src={data.image}>
              <Overlay
                onClick={() =>
                  this.handleServiceClick({ path: data.path, history })
                }
              >
                <Header>{data.header}</Header>
                {data.lunch && lunchOpenHoursWeekdays && (
                  <SubHeader>Vardagar {lunchOpenHoursWeekdays}</SubHeader>
                )}
                {data.lunch && lunchOpenHoursWeekends && (
                  <SubHeader>Helg {lunchOpenHoursWeekends}</SubHeader>
                )}
                <SubHeader>{data.subheader}</SubHeader>
              </Overlay>
            </BackgroundImage>
          </Tablet>
        )}

        {type === "desktop" && (
          <Desktop>
            <BackgroundImage src={data.image}>
              {data.lunch ? (
                <Overlay>
                  <Lunch />
                </Overlay>
              ) : (
                <Overlay>
                  <Header>{data.header}</Header>
                  {data.description && (
                    <Description>{data.description}</Description>
                  )}
                  {data.tagline && <Tagline>{data.tagline}</Tagline>}
                  {data.buttons && (
                    <ButtonGroup>
                      {data.buttons.map((button) => (
                        <Button
                          key={button.path}
                          label={button.label}
                          path={button.path}
                        />
                      ))}
                    </ButtonGroup>
                  )}
                  {data.smallImages && (
                    <ImageGroup>
                      {data.smallImages.map((img) => (
                        <SmallImage key={img.src} src={img.src} alt={img.alt} />
                      ))}
                    </ImageGroup>
                  )}
                  {data.about && (
                    <Description>
                      <OrnamentDesktopTop />
                      <br />
                      <p>
                        Hemlagat…Smaka på ordet en stund. Det finns inte mycket
                        som känns mer ”på riktigt”. Hemlagat skickar tankarna
                        till mat lagad med kärlek, tanke och omsorg. Så som det
                        ska vara.
                      </p>
                      <br />
                      <p>
                        Något som - om Farmor Edith fortfarande hade levt
                        troligtvis hade fallit henne i smaken. För det var ju så
                        det var. Då, i köket i huset, i Alingsås. Här fick de
                        härliga råvarorna all den kärlek de förtjänade. Här
                        lagade Edith mat och mycket annat med passion och
                        kunskap som ärvts från generation till generation.
                      </p>
                      <br />
                      <p>
                        Och det arvet för vi nu vidare in i Ediths Kök. För hur
                        vi än vänder på det så finns det inget bättre än att
                        sitta ner och njuta av något som är hemlagat. Varmt
                        välkommen till Ediths. Här bjuds du på hemlagat. På
                        riktigt.
                      </p>
                      <br />
                      <OrnamentDesktopBottom />
                    </Description>
                  )}
                </Overlay>
              )}
            </BackgroundImage>
          </Desktop>
        )}
      </Container>
    );
  }
}
export default withRouter(Service);
