import React, { useContext, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import styled from "styled-components";
import Spinner from "react-spinkit";

import { RestaurantContext } from "../../context";
import { fetchContactData } from "../../utils";

import { Dish, MobilePageHeader } from "../../components";
import { BackgroundImage, Overlay, Header, Description } from "../../styles";
import { Mobile, Tablet, Desktop } from "../../theme";

import { FOOD_QUERY } from "../../graphql/query";

const Container = styled.div`
  flex: 1;
`;

const DishWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const TagSelector = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 0.5rem;
  background-color: #fafafa;

  @media (max-width: 600px) {
    margin-top: 0;
    max-width: 200px;
  }
`;

const TagButton = styled.button`
  background-color: #fafafa;
  color: ${(prop) => (prop.selected ? "black" : "grey")};
  margin-right: 0.5rem;
  padding: 4px 0px;
  border: 0;
  text-decoration: underline;
  text-decoration-color: ${(prop) => (prop.selected ? "black" : "#fafafa")};
  outline: none;
  cursor: pointer;
  font-size: 16px;
`;

const NotFound = styled.div`
  font-size: 16px;
  color: #e96565;
  font-weight: 500;
  margin-bottom: 2rem;
`;

let PrevCategory;
let showCategory;

const CateringFood = () => {
  const { restaurant, cartId, restaurantData } = useContext(RestaurantContext);
  const ContactData = fetchContactData(restaurant);

  const [food, setFood] = useState(null);
  const [tag, setTag] = useState([]);

  const { loading, data, error } = useQuery(FOOD_QUERY, {
    variables: { area: "mat", tag },
  });

  if (data && data.foodByFilter) {
    if (food !== data.foodByFilter) {
      setFood(data.foodByFilter);
    }
  }

  const handleClickTag = (item) => {
    if (item) {
      if (tag.includes(item)) {
        setTag([...tag.filter((tag) => tag !== item)]);
      } else {
        setTag([...tag, item]);
      }
    } else {
      setTag([]);
    }
  };

  let text;

  if (restaurant === "edi-ali-sod") {
    text = `För beställning kontakta Marianne på ${ContactData.phone} eller ${ContactData.email}`;
  }

  return (
    <Container>
      <Mobile>
        <MobilePageHeader
          header="BUFFÉ OCH FEST"
          text="Bröllop, student eller bara fest i goda vänners lag. Ediths catering-mat passar alla möjliga festligheter. Skrolla ner och se vad ni är sugna på."
          more={text}
        />
      </Mobile>
      <Tablet>
        <MobilePageHeader
          header="BUFFÉ OCH FEST"
          text="Bröllop, student eller bara fest i goda vänners lag. Ediths catering-mat passar alla möjliga festligheter. Skrolla ner och se vad ni är sugna på."
          more={text}
        />
      </Tablet>
      <Desktop>
        <BackgroundImage src="laxssida" small>
          <Overlay small>
            <Header>BUFFÉ OCH FEST</Header>
            <Description center>
              Bröllop, student eller bara fest i goda vänners lag. Ediths
              catering-mat passar alla möjliga festligheter. Skrolla ner och se
              vad ni är sugna på.
            </Description>
            {text && <Description center>{text}</Description>}
          </Overlay>
        </BackgroundImage>
      </Desktop>
      <DishWrapper>
        {restaurantData &&
        restaurantData.feature &&
        restaurantData.feature.catering ? (
          <TagSelector>
            <TagButton
              onClick={() => handleClickTag()}
              selected={tag && tag.length === 0 ? true : false}
            >
              Alla
            </TagButton>
            <TagButton
              onClick={() => handleClickTag("glutenfri")}
              selected={tag && tag.includes("glutenfri") ? true : false}
            >
              Glutenfri
            </TagButton>
            <TagButton
              onClick={() => handleClickTag("lactosfri")}
              selected={tag && tag.includes("lactosfri") ? true : false}
            >
              Lactosfri
            </TagButton>
            <TagButton
              onClick={() => handleClickTag("mandelfri")}
              selected={tag && tag.includes("mandelfri") ? true : false}
            >
              Mandelfri
            </TagButton>
            <TagButton
              onClick={() => handleClickTag("mjölkfri")}
              selected={tag && tag.includes("mjölkfri") ? true : false}
            >
              Mjölkfri
            </TagButton>
            <TagButton
              onClick={() => handleClickTag("nötfri")}
              selected={tag && tag.includes("nötfri") ? true : false}
            >
              Nötfri
            </TagButton>
            <TagButton
              onClick={() => handleClickTag("äggfri")}
              selected={tag && tag.includes("äggfri") ? true : false}
            >
              Äggfri
            </TagButton>
          </TagSelector>
        ) : null}

        {loading ? (
          <Spinner
            name="three-bounce"
            color="rgba(0, 0, 0, 0.6)"
            style={{ paddingBottom: "2rem" }}
          />
        ) : null}

        {error ? <div>{error.message}</div> : null}

        {food && food.length === 0 ? (
          <NotFound>Inga buffe eller fest rätter hittades!</NotFound>
        ) : null}

        {food &&
          food.length > 0 &&
          food.map((dish) => {
            if (PrevCategory !== dish.category) {
              showCategory = true;
            } else {
              showCategory = false;
            }
            PrevCategory = dish.category;
            return (
              <Dish
                key={dish.id}
                dish={dish}
                cartId={cartId}
                showCategory={showCategory}
              />
            );
          })}
      </DishWrapper>
    </Container>
  );
};

export default CateringFood;
