import React, { createContext, useState, useEffect } from "react";
import _ from "lodash/array";

import { useLocalStorage, fetchContactData } from "../utils";

const RestaurantContext = createContext();

const AppContext = ({ children }) => {
  const [restaurant, setRestaurant] = useLocalStorage(
    "edithSelectedRestaurant",
    null
  );
  const [cartId, setCartId] = useLocalStorage("edithCartId", null);
  const [restaurantData, setRestaurantData] = useState({});
  const [favorites, setFavorites] = useLocalStorage("edithFavorites", []);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectRestaurantOpen, setSelectRestaurantOpen] = useState(false);

  const handleFavoriteAdd = dish => {
    setFavorites([...favorites, dish]);
  };

  const handleFavoriteRemove = dish => {
    const position = _.findIndex(favorites, { id: dish.id });

    if (position !== -1) {
      favorites.splice(position, 1);
      setFavorites([...favorites]);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleRestaurant = () => {
    setSelectRestaurantOpen(!selectRestaurantOpen);
  };

  useEffect(() => {
    if (restaurant) {
      setRestaurantData(fetchContactData(restaurant));
    }
  }, [restaurant]);

  const defaultRestaurantContext = {
    restaurant,
    setRestaurant,
    cartId,
    setCartId,
    restaurantData,
    favorites,
    handleFavoriteAdd,
    handleFavoriteRemove,
    menuOpen,
    toggleMenu,
    selectRestaurantOpen,
    toggleRestaurant
  };

  return (
    <RestaurantContext.Provider value={defaultRestaurantContext}>
      {children}
    </RestaurantContext.Provider>
  );
};

export { AppContext, RestaurantContext };
