import React from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.min.css";

import { AppContext } from "./context";

import "./App.css";
import { theme } from "./theme";

import {
  CheckedSpacer,
  Header,
  MobileMenu,
  RestaurantSelectorMobile,
  Routes,
  ScrollToTop,
  UpdateChecker,
} from "./components";

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
});

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const App = () => {
  return (
    <AppContext>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ScrollToTop>
                <AppContainer>
                  <UpdateChecker />
                  <RestaurantSelectorMobile />
                  <Header />
                  <CheckedSpacer />
                  <MobileMenu />
                  <Routes />
                  <CheckedSpacer />
                </AppContainer>
              </ScrollToTop>
            </BrowserRouter>
          </ThemeProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </AppContext>
  );
};

export default App;
