import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  @media (min-width: 601px) and (max-width: 900px) {
    margin: 4rem;
  }

  @media (min-width: 901px) {
    margin: 6rem;
  }
`;

const Header = styled.div`
  font-family: "Timber";
  font-size: 45px;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-transform: uppercase;
  text-align: center;
  line-height: 1;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 65px;
  }

  @media (min-width: 901px) {
    font-size: 90px;
  }
`;

const SubHeader = styled.div`
  font-size: 22px;
  margin-bottom: 0.5rem;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.8)" : "#fff")};

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
  }

  @media (min-width: 901px) {
    font-size: 34px;
  }
`;

const SubHeader2 = styled.div`
  font-size: 19px;
  margin-bottom: 0.5rem;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.8)" : "#fff")};

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 23px;
  }

  @media (min-width: 901px) {
    font-size: 28px;
  }
`;

const Text = styled.div`
  font-size: 17px;
  color: ${(prop) => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: center;
  text-align: ${(prop) => (prop.center ? "center" : "left")};

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 21px;
  }

  @media (min-width: 901px) {
    font-size: 25px;
  }
`;

const Spacer = styled.div`
  height: 2rem;

  @media (min-width: 901px) {
    height: 3rem;
  }
`;
const PrivacyPolicy = () => {
  return (
    <Container>
      <Wrapper>
        <Header dark>Integritetspolicy</Header>
        <Spacer />
        <Text dark center>
          Här förklarar vi kort och tydligt hur vi på Ediths Kök samlar in och
          hanterar personlig information från våra användare och kunder som
          besöker - edithskok.se - samt hur vi lagrar och använder denna
          information. Detta i avsikt att följa EU:s riktlinjer gällande GDPR.
        </Text>
        <Spacer />
        <SubHeader2 dark>Uppgifter om oss</SubHeader2>
        <Text dark>
          Ediths Kök
          <br />
          Hemvägen 19
          <br />
          441 39 Alingsås
          <br />
          Telefon: <a href="tel:004632278445">0322-784 45</a>
          <br />
          E-post: <a href="mailto:koket@edithskok.se">koket@edithskok.se</a>
          <br />
          <br />
          Ediths Kök är en del i Nolgården koncernen.
        </Text>
        <Spacer />
        <SubHeader dark>Vilken information sparar vi</SubHeader>
        <Text dark>
          När du är inne på vår hemsida använder vi Google Analytics för att för
          att samla in information om ditt besök. Det innebär att vi kan se
          vilken ort du kommer ifrån, när du besökt oss, hur länge du stannat
          samt vilka sidor du besökt.
          <br />
          <br />
          Har du beställt catering av oss sparar vi förutom information om vad
          du beställt även ditt namn, e-post samt mobilnummer.
        </Text>
        <Spacer />
        <SubHeader dark>Hur vi använder informationen</SubHeader>
        <Text dark>
          Vårt data om dina besök hos oss använder vi för att göra din
          upplevelse hos oss ännu bättre samt försöka tillgodose just dina
          behov. Vi använder den även för att förbättra vår hemsida och ditt
          behov av kundservice och hjälp.
          <br />
          <br />I det fall du är catering kund använder vi informationen för att
          hålla dig uppdaterad om din order. Att vi tagit emot ordern och när
          den är klar att hämta. Vi använder även informationen i det fall vi
          behöver komma i kontakt med dig för att ställa frågor om din order.
          Allt för att du skall vara så nöjd som möjligt med din upplevelse hos
          oss.
        </Text>
        <Spacer />
        <SubHeader dark> Hur länge vi sparar informationen</SubHeader>
        <Text dark>
          Vi sparar information så länge som vi anser det vara nödvändigt. Allt
          för att vi skall kunna svara på frågor från dig som kund om en
          tidigare order. Vill du att vi raderar dina uppgifter så är det bara
          att du hör av dig!
        </Text>
        <Spacer />
        <SubHeader dark>Vem kan se information</SubHeader>
        <Text dark>
          Det är endast personer som jobbar med eller hos oss som kan se dina
          uppgifter. Vi säljer inte någon information vidare till 3:e part.
          <br />
          <br />I det fall det förekommer misstanke om brott eller annan illegal
          aktivitet använder vi informationen för att förhindra eller vidta
          nödvändiga åtgärder.
        </Text>
        <Spacer />
        <SubHeader dark>Säkerhet</SubHeader>
        <Text dark>
          Vi vidtar en rad åtgärder för att skydda din information. Vi använder
          oss av kryptering för att skydda alla uppgifter som överförs över
          internet. Endast personal som ska uträtta ett specifikt jobb (t.ex.
          hantering av din catering order eller kundservice), får tillgång till
          din information. De servrar som används för att lagra informationen
          befinner sig i en säker miljö.
        </Text>
        <Spacer />

        <SubHeader dark>Cookies</SubHeader>
        <Text dark>
          Våra cookies förbättrar tillgången till vår webbplats - edithskok.se -
          och identifierar återkommande besökare. Vår användning av cookies
          kopplas över huvud taget inte till personligt identifierbar
          information på vår webbplats.
          <br />
          <br />
          Det är möjligt för dig att stänga av cookies i din webbläsares
          inställningar. Detta kan dock leda till att du får en försämrad
          upplevelse och inte kan använda alla tjänster vi erbjuder.
        </Text>
        <Spacer />

        <SubHeader dark>Övrigt</SubHeader>
        <Text dark>
          Har du synpunkter på vår Integritetspolicy eller kan hjälpa oss att
          öka vår transparens, tveka inte att kontakta oss.
        </Text>
        <Spacer />

        <SubHeader dark>Samtycke</SubHeader>
        <Text dark>
          Genom att använda vår webbplats - edithskok.se - godkänner du vår
          integritetspolicy.
        </Text>
      </Wrapper>
    </Container>
  );
};

export default PrivacyPolicy;
