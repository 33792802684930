import styled from "styled-components";

const Description = styled.div`
  font-size: 17px;
  line-height: 1.4;
  padding: 1rem;
  color: ${prop => (prop.dark ? "rgba(0, 0, 0, 0.6)" : "#fff")};
  text-align: ${prop => (prop.center ? "center" : "left")};

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 22px;
    padding-top: 1.5rem;
    width: 500px;
  }

  @media (min-width: 901px) {
    font-size: 22px;
    padding-top: 1.5rem;
    width: 550px;
  }
`;

export default Description;
