import React from "react";
import styled from "styled-components";

const LinkContainer = styled.a`
  font-size: 18px;
  text-align: center;
  color: white;
  border: ${props => (props.noborder ? "none" : "1px solid white")};
  padding: 0.2rem 0.5rem 0rem 0.5rem;
  width: ${props => (props.width ? props.width : "inherit")};
  margin: 0.5rem;
  background: transparent;
  text-transform: uppercase;
  text-decoration: none;

  @media (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
    padding: 0.2rem 0.5rem 0rem 0.5rem;
  }

  @media (min-width: 901px) {
    font-size: 24px;
    padding: 0.5rem 1rem 0rem 1rem;
    height: 38px;
    margin: 0.5rem;

    &:hover {
      background: ${props => (props.nohover ? "none" : "rgba(0, 0, 0, 0.2)")};
    }
  }
`;

const Link = ({
  path,
  label,
  width,
  target = "_blank",
  noborder,
  nohover,
  children
}) => (
    <LinkContainer
      href={path}
      width={width}
      target={target}
      noborder={noborder}
      nohover={nohover}
      rel="noopener"
    >
      {label ? label : children}
    </LinkContainer>
  );

export default Link;
