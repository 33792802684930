import styled from "styled-components";

const Version = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  padding-bottom: 8px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;

export default Version;
