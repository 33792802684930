import React from "react";

import { BackgroundImage, Header, Description, Overlay } from "../../styles";
import { RestaurantSelector } from "../../components";
import { Desktop, Mobile, Tablet } from "../../theme";

const RestaurantSelectorLading = () => {
  return (
    <div>
      <Mobile>
        <BackgroundImage src="gryta">
          <Overlay>
            <Header>Välkommen att smaka hemlagat på riktigt.</Header>
            <br />
            <RestaurantSelector />
          </Overlay>
        </BackgroundImage>
      </Mobile>
      <Tablet>
        <BackgroundImage src="gryta" small>
          <Overlay small>
            <Header>Välkommen att smaka hemlagat på riktigt.</Header>
            <Description center>
              Mat lagad på fina råvaror med omtanke och kärlek. <br />
              Precis som det ska vara.
            </Description>
            <RestaurantSelector />
          </Overlay>
        </BackgroundImage>
      </Tablet>
      <Desktop>
        <BackgroundImage src="gryta" small>
          <Overlay small>
            <Header>Välkommen att smaka hemlagat på riktigt.</Header>
            <Description center>
              Mat lagad på fina råvaror med omtanke och kärlek. <br />
              Precis som det ska vara.
            </Description>
            <RestaurantSelector />
          </Overlay>
        </BackgroundImage>
      </Desktop>
    </div>
  );
};

export default RestaurantSelectorLading;
