import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  padding: 3rem;
  margin-bottom: 0.5rem;
  background-color: #eee;
`;

const FourOFour = () => (
  <Container>
    404 - Sidan hittades inte. Gå till <Link to="/">startsidan</Link>.
  </Container>
);

export default FourOFour;