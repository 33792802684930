import React from "react";

const parseText = text => {
  if (text) {
    return text.split("\n").map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ));
  } else {
    return null;
  }
};

export default parseText;
