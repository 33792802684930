import gql from "graphql-tag";

const CHARGE_CART_MUTATION = gql`
  mutation CHARGE_CART_MUTATION($id: String, $token: String) {
    chargeCart(id: $id, token: $token)
  }
`;

const ADD_TO_CART_MUTATION = gql`
  mutation ADD_TO_CART_MUTATION(
    $id: ID
    $itemId: String!
    $quantity: Int!
    $optionId: String
  ) {
    addToCart(
      id: $id
      itemId: $itemId
      quantity: $quantity
      optionId: $optionId
    ) {
      id
    }
  }
`;

const REMOVE_FROM_CART_MUTATION = gql`
  mutation REMOVE_FROM_CART_MUTATION($id: ID!, $itemId: ID!) {
    removeFromCart(id: $id, itemId: $itemId) {
      id
    }
  }
`;

const CHANGE_ITEM_QUANTITY_MUTATION = gql`
  mutation CHANGE_ITEM_QUANTITY_MUTATION(
    $id: ID!
    $itemId: ID!
    $quantity: Int!
  ) {
    changeItemQuantity(id: $id, itemId: $itemId, quantity: $quantity) {
      id
    }
  }
`;

const CREATE_ORDER_MUTATION = gql`
  mutation CREATE_ORDER_MUTATION($order: OrderInput!) {
    createOrder(order: $order) {
      id
      orderId
      total
      user {
        name
        email
      }
      comment
      deliveryDate
    }
  }
`;

export {
  CHARGE_CART_MUTATION,
  ADD_TO_CART_MUTATION,
  REMOVE_FROM_CART_MUTATION,
  CHANGE_ITEM_QUANTITY_MUTATION,
  CREATE_ORDER_MUTATION,
};
