import React, { useContext } from "react";
import styled from "styled-components";

import { RestaurantContext } from "../../context";
import { Restaurants } from "../../db";

import { Header, MapMarker } from "../../styles";

const Container = styled.div`
  transition: 0.5s;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 100;
  background-color: black;
`;

const Card = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 100%;

  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  border-radius: 15px 15px 0 0;
`;

const Restaurant = styled.div`
  padding: 1rem;
  font-size: 1.3rem;
`;

const Spacer = styled.div`
  padding: 1rem;
`;

const RestaurantSelectorMobile = () => {
  const { selectRestaurantOpen, toggleRestaurant, setRestaurant } = useContext(
    RestaurantContext
  );

  const handleSelectRestaurant = restaurant => {
    setRestaurant(restaurant);
    toggleRestaurant();
  };

  if (selectRestaurantOpen) {
    return (
      <Container>
        <Overlay />

        <Card>
          <Spacer />
          <Header dark>Välj restaurang</Header>
          {Restaurants.map(restaurant => {
            return (
              <Restaurant
                key={restaurant.value}
                onClick={() => handleSelectRestaurant(restaurant.value)}
              >
                <MapMarker height="14" width="14" /> {restaurant.label}
              </Restaurant>
            );
          })}
          <Spacer />
        </Card>
      </Container>
    );
  } else {
    return null;
  }
};

export default RestaurantSelectorMobile;
